import {FunctionComponent, PropsWithChildren} from 'react';
import './tag.css';
import classNames from 'classnames';

type TagProps = PropsWithChildren & {
    onClick?: () => void
};

const Tag: FunctionComponent<TagProps> = ({children, onClick}) => {
    return (
        <div className={classNames('Tag', {clickable: onClick !== undefined})}>
            {children}
        </div>
    );
}

export default Tag;
