import {FunctionComponent} from 'react';
import './error-list.css';

type ErrorListProps = {
    errors: Array<string>
};

const ErrorList: FunctionComponent<ErrorListProps> = ({errors}) => {
    if (errors.length === 0) return null;

    return (
        <ul className="ErrorList">
            {errors.map(error => <li key={error}>{error}</li>)}
        </ul>
    );
}

export default ErrorList;
