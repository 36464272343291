import {Rack, SaveRack} from '../../types/rack';
import {FunctionComponent, useState} from 'react';
import Tabs from '../common/tabs';
import Tab from '../common/tab';
import TabContent from '../common/tab-content';
import EditRackMeta from './edit-rack-meta';
import FormRow from '../form/form-row';
import EditRackSections from './edit-rack-sections';
import EditRackCodes from './edit-rack-codes';
import Button from '../common/button';
import {InfoIcon, EllipsisIcon, LocationIcon} from '@primer/octicons-react';

export type EditRackProps = {
    rack?: Rack
    onDone: (anyChanges: boolean, rackId?: number) => void
}

const EditRack: FunctionComponent<EditRackProps> = ({
                                                                  rack,
                                                                  onDone
                                                              }) => {
    const [wasChanged, setWasChanged] = useState<boolean>(false);
    const [sessionRack, setSessionRack] = useState<SaveRack>({
        enable: rack?.enable ?? true,
        name: rack?.name ?? '',
        note: rack?.note ?? '',
        id: rack?.id
    });

    const [tabIx, setTabIx] = useState<number>(0);

    const isFinished = rack && rack.sections && rack.sections.length > 0 && rack.codes.length > 0;

    return (
        <Tabs tabIx={tabIx} onTabSelected={ev => {
            setTabIx(ev.tabIx)
        }}>
            <Tab label="Meta" icon={<InfoIcon/>}>
                <TabContent>
                    <EditRackMeta doneLabel={isFinished ? 'Save' : 'Save & Next'}
                                  rack={sessionRack}
                                  onDone={newSessionRack => {
                                      if (!rack || newSessionRack.name !== rack.name || newSessionRack.note !== rack.note) setWasChanged(true);
                                      setSessionRack(newSessionRack)
                                      if (!isFinished) setTabIx(tabIx + 1);
                                  }}
                                  onCancel={wasChanged ? undefined : () => onDone(wasChanged)}
                    />
                </TabContent>
            </Tab>
            <Tab label="Sections" icon={<EllipsisIcon/>} disabled={sessionRack.id === undefined}>
                <TabContent>
                    {sessionRack && sessionRack.id && <EditRackSections rackId={sessionRack.id} onChanged={() => setWasChanged(true)}/>}
                    <FormRow>
                        <Button onClick={() => {
                            if (isFinished) onDone(wasChanged);
                            else setTabIx(tabIx + 1);
                        }}>{isFinished ? 'Save' : 'Save & Next'}</Button>
                    </FormRow>
                </TabContent>
            </Tab>
            <Tab icon={<LocationIcon/>} label="Distribution Codes" disabled={sessionRack.id === undefined}>
                <TabContent>
                    {sessionRack && sessionRack.id && (
                        <EditRackCodes rackId={sessionRack.id}
                                       onChanged={() => setWasChanged(true)}
                                       commitLabel="Save & Close"
                                       commit={() => {
                                           onDone(wasChanged, sessionRack.id);
                                       }}
                        />
                    )}
                    {/*<FormRow>*/}
                    {/*    <Button onClick={() => {*/}
                    {/*        onDone(wasChanged, sessionRack.id);*/}
                    {/*    }}>*/}
                    {/*        Save &amp; Close*/}
                    {/*    </Button>*/}
                    {/*</FormRow>*/}
                </TabContent>
            </Tab>
        </Tabs>
    )
        ;
}

export default EditRack;
