import {FunctionComponent, PropsWithChildren} from 'react';
import './tags.css';

type TagsProps = PropsWithChildren;

const Tags: FunctionComponent<TagsProps> = ({children}) => {
    return (
        <div className="Tags">
            {children}
        </div>
    );
}

export default Tags;
