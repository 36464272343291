import {createContext} from 'react';
import {ContractPositions} from '../utils/rack';

type PlacementContextType = {
    showDuplicateContract: (contract?: string) => void
    checkDuplicateContract?: string
    contractPositions: ContractPositions
}

const PlacementContext = createContext<PlacementContextType>({
    showDuplicateContract: () => {},
    contractPositions: {}
});

export default PlacementContext;
