import {
    FunctionComponent,
    MouseEventHandler,
    ReactNode,
    useState,
    useRef,
} from 'react';
import DragDropManager, {DraggingEvent} from './manager';

const Droppable: FunctionComponent<DroppableProps> = ({
                                                          accept,
                                                          children,
                                                          className,
                                                          onDragOut,
                                                          onDrop,
                                                          onDragOver
                                                      }) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const timer = useRef<number | null>(null);
    const handleMouseOver: MouseEventHandler<HTMLDivElement> = (ev) => {
        const context = DragDropManager.instance().getContext();
        if (!context) return;

        if (isItemInDroppableBucket(context.bucket, accept)) {
            if (timer.current) window.clearTimeout(timer.current);
            timer.current = window.setTimeout(() => {
                setIsHovering(true);
                if (onDragOver) {
                    onDragOver({item: context.item, nativeEvent: ev.nativeEvent});
                }
            }, 100);
        }
    }

    const handleMouseOut: MouseEventHandler<HTMLDivElement> = (ev) => {
        const context = DragDropManager.instance().getContext();
        if (!context) return;

        if (isItemInDroppableBucket(context.bucket, accept)) {
            setIsHovering(false);
            if (timer.current) {
                clearTimeout(timer.current);
                timer.current = null;
            }
            if (onDragOut) {
                onDragOut({item: context.item, nativeEvent: ev.nativeEvent});
            }
        }
    }

    const handleMouseUp: MouseEventHandler<HTMLDivElement> = (ev) => {
        const context = DragDropManager.instance().getContext();
        if (!context) return;

        if (isItemInDroppableBucket(context.bucket, accept)) {
            setIsHovering(false);
            if (onDrop) {
                const d: DraggingEvent = {
                    item: context.item,
                    nativeEvent: ev.nativeEvent
                };
                onDrop(d);
            }
        }
    };

    const content = typeof (children) === 'function' ? children({
        isHovering,
        item: DragDropManager.instance().getContext()?.item,
        itemBucket: DragDropManager.instance().getContext()?.bucket
    }) : children;

    // const DroppableComponent = withDroppable(<div>{children}</div>);
    //
    // return <DroppableComponent onDrop={onDrop} onDragOut={onDragOut} onDragOver={onDragOver}/>;
    return <div className={className}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}
                onMouseUp={handleMouseUp}
    >
        {content}
    </div>;
}

type RenderableProps<T> = {
    isHovering: boolean
    item: undefined | T
    itemBucket?: string
}

type DroppableProps<T = any> = {
    children?: ReactNode | ((props: RenderableProps<T>) => ReactNode)
    className?: string
    accept?: Array<T> // Only accept items with "bucket" as an attribute
    onDragOver?: (ev: DraggingEvent) => void
    onDragOut?: (ev: DraggingEvent) => void
    onDrop?: (ev: DraggingEvent) => void
}

function isItemInDroppableBucket(itemBucket?: string, acceptableBuckets?: Array<string>) {
    return (!acceptableBuckets || !itemBucket || (itemBucket && acceptableBuckets.findIndex(bucket => itemBucket === bucket) >= 0));
}

export default Droppable;
