import {FunctionComponent, PropsWithChildren, useContext, useState} from 'react';
import './placement.css';
import {useNavigate, useParams} from 'react-router-dom';
import {RackRouteParams} from '../types/rack-route-params';
import {Contract} from '../types/contract';
import RackLoader from '../components/racks/rack-loader';
import RackLoaderScreen from '../components/racks/rack-loader-screen';
import {Rack} from '../types/rack';
import classNames from 'classnames';
import RackLayout from '../components/racks/rack-layout';
import ContractList from '../components/advertiser-list/contract-list';
import update from 'immutability-helper';
import MenuBar from '../components/menu/menu-bar';
import Menu from '../components/menu/menu';
import api from '../services/api';
import AppContext from '../contexts/app-context';
import config from '../config/config';
import EditRackModal from '../components/racks/edit-rack-modal';
import {ModalOptions} from '../services/modal-manager';
import LoadingIndicator from '../components/common/loading-indicator';
import {ArrowLeftIcon, DownloadIcon, GearIcon, ThumbsupIcon} from '@primer/octicons-react';
import PlacementContext from '../contexts/placement-context';
import {rackContractPositions} from '../utils/rack';
import Button from '../components/common/button';

type PlacementProps = PropsWithChildren;

enum LoadingStatus {
    Initializing = 'Initializing',
    Failed = 'Failed',
    Saving = 'Saving',
    Done = 'Done'
}

const PlacementPage: FunctionComponent<PlacementProps> = (props) => {
    const [status, setStatus] = useState<LoadingStatus>(LoadingStatus.Initializing);
    const [collapsed, setCollapsed] = useState<boolean>(false);
    const [rack, setRack] = useState<Rack | undefined>();
    const [savingCount, setSavingCount] = useState<number>(0);
    const [contracts, setContracts] = useState<Array<Contract>>([]);
    const [highlightContract, setHighlightContract] = useState<string | undefined>();

    const navParams = useParams<RackRouteParams>();

    const navParamsRackId = navParams.rackId ? parseInt(navParams.rackId) : undefined;
    const nav = useNavigate();
    const appContext = useContext(AppContext);

    function delayedSavingDone() {
        setTimeout(() => {
            setStatus(LoadingStatus.Done);
        }, 1000);
    }

    function handleSectionMove(ix: number, direction: number) {
        if (!rack || !rack.sections) return;
        const section = rack.sections[ix];
        setSavingCount(prevCount => prevCount + 1);
        setStatus(LoadingStatus.Saving);
        const newSections = update(rack.sections, {
            $splice: [[ix, 1], [ix+direction, 0, section]]
        });
        setRack(update(rack, {
            sections: {$set: newSections}
        }));

        api().racks.reorderSections(rack.id, newSections.map(section => section.id)).then(() => {
            setSavingCount(prevCount => prevCount - 1);
            delayedSavingDone();
        })
    }

    return (
        <PlacementContext.Provider value={{
            showDuplicateContract: (contract) => {
                setHighlightContract(contract);
            },
            checkDuplicateContract: highlightContract,
            contractPositions: rackContractPositions(rack)
        }}>
            <div className={classNames('placement-rack', {full: collapsed})}>
                <MenuBar>
                    <Menu label="Back to Racks" icon={<ArrowLeftIcon/>} onClick={() => nav('/racks')}/>
                    <Menu label="Generate PDF" icon={<DownloadIcon/>} onClick={() => {
                        rack && window.open(config.apiBase + '/racks/' + rack.id + '/pdf', '_blank');
                    }}/>
                    <div>Rack #{rack ? rack.id : null}</div>
                    <div className="flex" style={{marginLeft: 'auto', alignItems: 'center'}}>
                        <Menu label="Configure"
                              icon={<GearIcon/>}
                              onClick={() => {
                                  const modalOptions = new ModalOptions();
                                  modalOptions.canClickOutside = false;
                                  appContext.modalManager.pushModal(
                                      <EditRackModal rack={rack}
                                                     onDone={(wasChanged, rackId) => {
                                                         setStatus(LoadingStatus.Initializing);
                                                         setRack(undefined);
                                                         appContext.modalManager.popModal();
                                                     }}
                                      />,
                                      modalOptions
                                  )
                              }}/>
                        <div className={classNames('placement-rack-save-status', savingCount > 0 ? 'saving' : 'saved')}>{savingCount > 0 ? (
                            <><LoadingIndicator/> Saving {savingCount}</>
                        ) : (
                            <><ThumbsupIcon/> Auto-saved</>
                        )}</div>
                    </div>
                </MenuBar>
                <div style={{padding: 5}}>
                    {rack ? (
                        <RackLayout rack={rack}
                                    sections={rack.sections ?? []}
                                    onMoveSectionUp={ix => handleSectionMove(ix, -1)}
                                    onMoveSectionDown={ix => handleSectionMove(ix, 1)}
                                    setPocket={(sectionIx, rowIx, colIx, pocket) => {
                                        if (!rack || !rack.sections) {
                                            alert('Sorry, an unknown issue occured while trying to set the pocket.');
                                            return Promise.resolve();
                                        }

                                        setSavingCount(prevState => prevState + 1);
                                        setStatus(LoadingStatus.Saving);

                                        return api().racks.setPocket(rack.id, rack.sections[sectionIx].id, rowIx, colIx, pocket).then(serverPocket => {
                                            setSavingCount(prevState => prevState - 1);
                                            delayedSavingDone();
                                            let pocketIx = -1;
                                            if (rack && rack.sections) {
                                                pocketIx = rack.sections[sectionIx].pockets.findIndex(checkPocket => {
                                                    return checkPocket.pos_y === rowIx && checkPocket.pos_x === colIx;
                                                });
                                            }

                                            if (pocketIx >= 0) {
                                                if (serverPocket) { // Replacing existing pocket
                                                    setRack(update(rack, {
                                                        sections: {
                                                            [sectionIx]: {
                                                                pockets: {$splice: [[pocketIx, 1, serverPocket]]}
                                                            }
                                                        }
                                                    }));
                                                } else { // Removing pocket
                                                    setRack(update(rack, {
                                                        sections: {
                                                            [sectionIx]: {
                                                                pockets: {
                                                                    $splice: [[pocketIx, 1]]
                                                                }
                                                            }
                                                        }
                                                    }))
                                                }
                                            } else {
                                                if (serverPocket) {
                                                    setRack(update(rack, {
                                                        sections: {
                                                            [sectionIx]: {
                                                                pockets: {$push: [serverPocket]}
                                                            }
                                                        }
                                                    }));
                                                }
                                            }
                                        }).catch(e => {
                                            setSavingCount(prevState => prevState - 1);
                                            delayedSavingDone();;
                                            throw e;
                                        });
                                    }}
                        />
                    ) : (
                        status === LoadingStatus.Failed ? <p>Unable to load rack.  <Button onClick={() => nav('/racks')}>Back to Racks</Button></p> : <p>Loading...</p>
                    )}
                </div>
            </div>
            <div className={classNames('placement-advertisers', {collapsed})}
                 onClick={() => {
                     if (collapsed) setCollapsed(false);
                 }}>
                <ContractList rack={rack}
                              contracts={contracts}
                              highlightContract={highlightContract}
                              isCollapsed={false}
                              collapse={() => {
                              }}
                              onOut={() => {
                                  setHighlightContract(undefined);
                              }}
                              onOver={contract => {
                                  setHighlightContract(contract);
                              }}
                />
            </div>
            {status === LoadingStatus.Initializing ? (
                <RackLoaderScreen>
                    <RackLoader
                        rackId={navParamsRackId}
                        onLoad={loaded => {
                            setRack(loaded.rack);
                            setContracts(loaded.contracts);
                            setStatus(LoadingStatus.Done);
                        }}
                        onFail={() => {
                            setStatus(LoadingStatus.Failed);
                        }}
                    />
                </RackLoaderScreen>
            ) : null}
            {status === LoadingStatus.Saving ? (
                <div className="placement-rack-saving">
                    {savingCount > 0 ? <><LoadingIndicator/> Saving</> : <><ThumbsupIcon size="medium"/> Saved</>}
                </div>
            ) : null}
        </PlacementContext.Provider>
    );
}

export default PlacementPage;
