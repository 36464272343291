import {FunctionComponent, useState} from 'react';
import {RackSection as RackSectionType} from '../../types/rack-section';
import './section.css';
import {RackPocket} from '../../types/rack-pocket';
import Button from '../common/button';
import ButtonSet from '../common/button-set';
import RackSectionGrid from './rack-section-grid';
import {ArrowDownIcon, ArrowUpIcon} from '@primer/octicons-react';

type SectionProps = {
    section: RackSectionType
    onAddSection: () => void
    setPocket: SetPocketHandler
    onMoveSectionUp: () => void
    onMoveSectionDown: () => void
    canMoveDown: boolean
    canMoveUp: boolean
}

const RackSectionLayout: FunctionComponent<SectionProps> = ({
                                                                canMoveDown,
                                                                canMoveUp,
                                                                onAddSection,
                                                                setPocket,
                                                                onMoveSectionUp,
                                                                onMoveSectionDown,
                                                                section
                                                            }) => {
    const [showPockets, setShowPockets] = useState<boolean>(true);

    return (
        <div className="racks-Section">
            <header>
                <div className="racks-Section-name" onClick={() => setShowPockets(!showPockets)}>
                    {section.name} {!showPockets && <Button onClick={() => {}}>Show Pockets</Button>}
                </div>
                <div className="racks-Section-actions">
                    {canMoveUp || canMoveDown ? (
                        <ButtonSet>
                            {/*<Button onClick={() => onAddSection()}>Add Section</Button>*/}
                            {canMoveUp ? <Button onClick={() => onMoveSectionUp()}><ArrowUpIcon/></Button> : null}
                            {canMoveDown ? <Button onClick={() => onMoveSectionDown()}><ArrowDownIcon/></Button> : null}
                        </ButtonSet>
                    ) : null}
                </div>
            </header>
            <main>
                {showPockets && <RackSectionGrid section={section}
                                 setPocket={setPocket}
                />}
            </main>
        </div>
    );
}

type SetPocketHandler = (rowIx: number, colIx: number, pocket?: RackPocket) => Promise<void>

export default RackSectionLayout;
