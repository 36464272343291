import {FunctionComponent, PropsWithChildren} from 'react';
import './pocket-options.css';

type PocketOptionsProps = PropsWithChildren;

const PocketOptions: FunctionComponent<PocketOptionsProps> = ({children}) => {
    return <div className="PocketOptions">
        {children}
    </div>;
}

export default PocketOptions;
