import {FunctionComponent, PropsWithChildren} from 'react';
import './button-set.css';

type ButtonSetProps = PropsWithChildren;

const ButtonSet: FunctionComponent<ButtonSetProps> = ({children}) => {
    return <div className="ButtonSet">{children}</div>;
}

export default ButtonSet;
