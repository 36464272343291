import {API} from '../api';

class BaseApi {
    constructor(protected api: API) {
    }

    // createDefaultConfigWithAuthorization<D = any>(authUser?: AuthUser): AxiosAuthorizationConfig<D> {
    //     const config: AxiosAuthorizationConfig<D> = {
    //         headers: {
    //             Authorization: this.createAuthorizationHeader(authUser)
    //         }
    //     }
    //
    //     return config;
    // }
    //
    // createAuthorizationHeader(authUser?: AuthUser): string {
    //     if (!authUser) return '';
    //
    //     return 'Bearer ' + authUser.token;
    // }
}

export default BaseApi;
