import {FunctionComponent, PropsWithChildren} from 'react';
import './button.css';
import classNames from 'classnames';

type ButtonProps = PropsWithChildren & {
    className?: string
    onClick: () => void
    type?: ButtonType
    disabled?: boolean
};

enum ButtonType {
    Default = 'Default',
    Primary = 'Primary',
    Secondary = 'Secondary',
    Danger = 'Danger',
    Plain = 'Plain',
    Link = 'Link'
}

const Button: FunctionComponent<ButtonProps> = ({
                                                    children,
                                                    className,
                                                    onClick,
                                                    type,
                                                    disabled
                                                }) => {
    return <button className={classNames(
        'Button',
        className ? className : null,
        type ? type : ButtonType.Default,
        {disabled: disabled === true}
    )}
                   onClick={() => {
                       if (disabled !== true) onClick();
                   }}
    >
        {children}
    </button>;
}

export default Button;
export {ButtonType};
