import {Rack} from '../types/rack';

export type ContractPositions = {
    [contract: string]: Array<ContractPosition>
}

export type ContractPosition = {
    sectionId: number
    posX: number
    posY: number
}

function rackContractPositions(rack?: Rack): ContractPositions {
    let contracts: ContractPositions = {};

    if (!rack) return contracts;

    rack.sections?.forEach(section => {
        return section.pockets.forEach(pocket => {
            if (!pocket.contract) return;
            if (contracts[pocket.contract] === undefined) contracts[pocket.contract] = [];
            contracts[pocket.contract].push({
                sectionId: section.id,
                posX: pocket.pos_x,
                posY: pocket.pos_y
            });
        })
    });

    return contracts;
}

export {rackContractPositions}
