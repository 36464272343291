import {FunctionComponent, PropsWithChildren, ReactNode, useState} from 'react';
import './menu.css';

type MenuProps = PropsWithChildren & {
    icon?: ReactNode
    label: string
    onClick?: () => void
};

const Menu: FunctionComponent<MenuProps> = ({
                                                icon,
                                                label,
                                                children,
                                                onClick
                                            }) => {
    const [show, setShow] = useState<boolean>(false);
    return <div className="Menu">
        <div className="Menu-label"
             onClick={ev => {
                 if (onClick) onClick();
                 else {
                     // ev.stopPropagation();
                     const handleBodyClick: (ev: any) => void = (ev: any) => {
                         document.body.removeEventListener('click', handleBodyClick);
                         setShow(false);
                     }
                     setTimeout(() => {
                         document.body.addEventListener('click', handleBodyClick);
                     }, 100);
                     setShow(!show);
                 }
             }
             }>
            {icon ? <>{icon} </> : null}
            {label}
        </div>
        {show ? (
            <div className="Menu-options">
                {children}
            </div>
        ) : null}
    </div>;
}

export default Menu;
