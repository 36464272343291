import {FunctionComponent, PropsWithChildren, useContext, useEffect, useRef, useState} from 'react';
import './racks.css';
import Input from '../components/form/input';
import Button, {ButtonType} from '../components/common/button';
import Section from '../components/common/section';
import {Rack} from '../types/rack';
import LoadingIndicator from '../components/common/loading-indicator';
import api from '../services/api';
import Panel from '../components/common/panel';
import PanelHeader from '../components/common/panel-header';
import PanelContent from '../components/common/panel-content';
import Tabs from '../components/common/tabs';
import UserCodesTable from '../components/distribution-list/user-codes-table';
import RacksTable from '../components/distribution-list/racks-table';
import Tab from '../components/common/tab';
import AppContext from '../contexts/app-context';
import EditRackModal from '../components/racks/edit-rack-modal';
import {ModalOptions} from '../services/modal-manager';
import InputGroup from '../components/form/input-group';
import AccessLevel from '../enums/access-level';
import {AxiosError} from 'axios';
import Modal from '../components/modals/modal';
import ModalContent from '../components/modals/modal-content';
import ButtonSet from '../components/common/button-set';

type RacksPageProps = PropsWithChildren;

const RacksPage: FunctionComponent<RacksPageProps> = (props) => {
    const [racks, setRacks] = useState<Array<Rack> | undefined>();
    const [showAllUserRacks, setShowAllUserRacks] = useState<boolean>(false);
    const [keyword, setKeyword] = useState<string>('');
    const [filteredRacksIxes, setFilteredRacksIxes] = useState<Array<number>>([]);

    const appContext = useContext(AppContext);

    useEffect(() => {
        if (racks !== undefined) return;
        api().racks.getRacks(true).then(racks => {
            setRacks(racks);
        }).catch(e => {
            if (e instanceof AxiosError && e.response?.status === 401) {
                alert('You may have been logged out.  Try refreshing this page.  Server error: ' + e.message);
            } else {
                alert('Unknown error: ' + e.message);
            }
        });
    }, [racks]);

    const searchTimer = useRef<number>();

    const deleteRackAtIx = (ix: number): Promise<void> => {
        if (racks === undefined) return Promise.resolve();

        return new Promise((resolve, reject) => {
            const rack = racks[filteredRacksIxes[ix]];
            appContext.modalManager.pushModal(<Modal title="Confirm deletion?">
                <ModalContent>
                    <div className="text-center">
                        <p>Are you sure you want to delete &quot;{racks[filteredRacksIxes[ix]].name.length === 0 ? 'Untitled rack' : racks[filteredRacksIxes[ix]].name}&quot; racks?</p>
                        <ButtonSet>
                            <Button type={ButtonType.Primary}
                                    onClick={() => {

                                        api().racks.deleteRack(rack.id).then(data => {
                                            if (data.success) {
                                                alert('Success');
                                                appContext.modalManager.popModal();
                                            } else {
                                                alert('Failed: ' + (data.errors ? data.errors[0] : 'Unknown error'));
                                                appContext.modalManager.popModal();
                                            }
                                            setRacks(undefined); // Force refresh
                                        }).catch(e => {
                                            alert('There was an issue deleting the rack: ' + e.message);
                                            resolve();
                                            appContext.modalManager.popModal();
                                        });
                                    }}>Yes</Button>
                            <Button type={ButtonType.Danger}
                                    onClick={() => {
                                        appContext.modalManager.popModal();
                                    }}>No</Button>
                        </ButtonSet>
                    </div>
                </ModalContent>
            </Modal>)
        });
    }

    useEffect(() => {
        if (searchTimer.current) clearTimeout(searchTimer.current);

        searchTimer.current = window.setTimeout(() => {
            const ixes = racks === undefined ? [] : racks.map((rack, ix) => ix);

            const scores: Array<number> = [];
            const filteredIxes = ixes.filter(ix => {
                if (!racks) return false;
                else if (!showAllUserRacks && racks[ix].user.id !== appContext.authUser?.userId) return false;

                const lowerKeywords = keyword.toLowerCase().split(/ +/);

                let score: number = lowerKeywords
                    .map(lowerKeyword => {
                    const codeMatches = racks[ix]
                        .codes
                        .filter(code => code.toString()
                            .toLowerCase()
                            .match(lowerKeyword)
                        ).length > 0;

                    const nameMatch = racks[ix].name.toLowerCase().indexOf(lowerKeyword) >= 0;

                    const usernameMatches = racks[ix].user && racks[ix].user.name.toLowerCase().indexOf(lowerKeyword.toLowerCase()) >= 0;
                    // Calculate score for number of matches
                    return [codeMatches, nameMatch, usernameMatches].reduce((curScore, isMatch) => {
                            return isMatch === true ? curScore + 1 : curScore;
                        }, 0);
                })
                    .reduce((prevScore, curScore) => prevScore + curScore, 0);

                if (score < lowerKeywords.length) score = 0; // Score must be AT LEAST equal to the number of
                scores[ix] = score;
                return score > 0;
            });
            // Re-order results based on score
            filteredIxes.sort((a, b) => {
                if (scores[a] > scores[b]) return -1;
                else if (scores[a] < scores[b]) return 1;
                return 0;
            });
            setFilteredRacksIxes(filteredIxes);
        }, 250);
    }, [racks, keyword, showAllUserRacks, appContext.authUser]);

    return (
        <div className="RacksPage">
            <div className="text-right">
                <Button onClick={() => {
                    api().auth.logout().then(() => appContext.setAuthUser());
                }}>Log Out</Button>
            </div>
            {racks === undefined ? (
                <>
                    <LoadingIndicator/> Loading Racks
                </>
            ) : (
                <>
                    <Section>
                        <Panel>
                            <PanelHeader>
                                Racks
                                &nbsp;
                                <Button type={ButtonType.Primary}
                                        onClick={() => {
                                            const modalOptions = new ModalOptions();
                                            modalOptions.canClickOutside = false;
                                            appContext.modalManager.pushModal(
                                                <EditRackModal onDone={(wasChanged, rackId) => {
                                                    appContext.modalManager.popModal();
                                                    if (wasChanged) setRacks(undefined);
                                                }}
                                                />
                                                , modalOptions)
                                        }}
                                >
                                    Add Rack
                                </Button>
                            </PanelHeader>
                            <PanelContent>
                                <InputGroup>
                                    Search:
                                    <div>
                                        <Input value={keyword} onChange={val => setKeyword(val)}/>&nbsp;
                                        {appContext.authUser && [AccessLevel.EXECUTIVE, AccessLevel.OVERRIDE].indexOf(appContext.authUser.access) >= 0 && (
                                            <>
                                                <input id="showallusers"
                                                       type="checkbox"
                                                       onChange={() => {
                                                           setShowAllUserRacks(!showAllUserRacks);
                                                           // setRacks(undefined);
                                                       }} checked={showAllUserRacks}/> <label htmlFor="showallusers">Show All Users</label>
                                            </>
                                        )}
                                    </div>
                                    <div></div>
                                    <div>(e.g. name, distribution code, user)</div>
                                </InputGroup>
                            </PanelContent>
                            {filteredRacksIxes.length === 0 ? (
                                    <PanelContent>
                                        {keyword.length === 0 ? <div>You do not currently have any racks</div> :
                                            <div>No results matched your search.</div>}
                                    </PanelContent>
                                ) : (
                                    <Tabs initialTab={window.localStorage.getItem('distribution-rack-tab') ?? ''}
                                          onTabSelected={ev => {
                                              window.localStorage.setItem('distribution-rack-tab', ev.label)
                                          }}>
                                        <Tab label="By Code">
                                            <UserCodesTable racks={filteredRacksIxes.map(ix => racks[ix])}
                                                            deleteRackAtIndex={ix => deleteRackAtIx(ix)}
                                            />
                                        </Tab>
                                        <Tab label="By Name">
                                            <RacksTable racks={filteredRacksIxes.map(ix => racks[ix])}
                                                        refresh={() => {
                                                            setRacks(undefined)
                                                        }}
                                                        deleteRackAtIndex={ix => deleteRackAtIx(ix)}
                                            />
                                        </Tab>
                                    </Tabs>
                            )}
                        </Panel>
                    </Section>
                </>
            )}
        </div>
    );
}

export default RacksPage;
