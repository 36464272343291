import {FunctionComponent, useContext, useRef} from 'react';
import './contract.css';
import {Contract} from '../../types/contract';
import classNames from 'classnames';
import {CheckIcon} from '@primer/octicons-react';
import PlacementContext from '../../contexts/placement-context';

type ContractProps = {
    contract?: Contract
    highlight?: boolean
    onOver?: () => void
    onOut?: () => void
};

const ContractRow: FunctionComponent<ContractProps> = ({
                                                           contract,
                                                           highlight,
                                                           onOver,
                                                           onOut
                                                       }) => {
    const timer = useRef<number>();
    const placementContext = useContext(PlacementContext);

    function handleOver() {
        if (timer.current) clearTimeout(timer.current);
        if (onOver) {
            timer.current = window.setTimeout(() => onOver(), 50);
        }
    }

    function handleOut() {
        if (timer.current) window.clearTimeout(timer.current);
        if (onOut) {
            timer.current = window.setTimeout(() => onOut(), 25);
        }
    }

    const inPockets = contract && contract.contract && placementContext.contractPositions[contract.contract] !== undefined;

    return <div className={classNames('advertiser-list-Brochure', {
        highlight,
        pending: contract && contract.status === 'Pending'
    })}
                onMouseOver={() => handleOver()}
                onMouseOut={() => handleOut()}>
        {inPockets && (
            <div>
                <CheckIcon/>
            </div>
        )}
        <div>
            <div className="name">{contract ? contract.name : 'Custom'}</div>
            {contract && <div className="company">{contract.company}</div>}
            {/*{contract && <div className="contract">{contract.contract}</div>}*/}
            {contract && contract.status === 'Pending' && <div className="status">{contract.status}</div>}
            {contract && contract.start && contract.end && (
                <div className="dates">{contract && contract.start.toLocaleDateString()}-{contract && contract.end.toLocaleDateString()}</div>
            )}
        </div>
    </div>;
}

export default ContractRow;
