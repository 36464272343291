import {Status} from '../components/common/status-indicator';

type Task_OnChangeHandler = (task: Task) => void;
type Task_Executor = (task: Task) => Promise<void>;

class Task {
    private _status: Status = Status.Initializing;
    private _onChangeHandlers: Array<Task_OnChangeHandler> = [];

    constructor(private _label: string, private executor: Task_Executor) {
    }

    get label() {
        return this._label;
    }

    set label(label: string) {
        this._label = label;
        this.triggerOnChangeHandlers();
    }

    get status() {
        return this._status;
    }

    set status(status: Status) {
        this._status = status;
        this.triggerOnChangeHandlers();
    }

    onChange(handler: Task_OnChangeHandler): Task {
        this._onChangeHandlers.push(handler);
        return this;
    }

    private triggerOnChangeHandlers() {
        this._onChangeHandlers.forEach(handler => handler(this));
    }

    /**
     * Executes the task, captures any status changes and passes the
     * onStatusHandler to the executor function so that it notify us of status changes
     * @param onStatusHandler
     */
    execute(): Promise<void> {
        return this.executor(this).then(() => {
            const isFinalStatus = [Status.Failed, Status.Done].indexOf(this.status) >= 0;
            // Ensure that this task finishes with a "final" status, i.e. "Done"
            if (!isFinalStatus) {
                this.status = Status.Done;
            }
        });
    }
}

type TaskLoaderEvent = { target: TaskRunner }
type TaskLoader_StatusHandler = (ev: TaskLoaderEvent) => void

class TaskRunner {
    private onStatusHandlers: Array<TaskLoader_StatusHandler> = [];
    private onDoneHandlers: Array<TaskLoader_StatusHandler> = [];
    private _tasks: Array<Task> = [];
    private ix: number = -1;
    private processing: boolean = false;

    get tasks() {
        return this._tasks;
    }

    onStatus(callback: TaskLoader_StatusHandler) {
        this.onStatusHandlers.push(callback);
    }

    onDone(callback: TaskLoader_StatusHandler) {
        this.onDoneHandlers.push(callback);
    }

    addTask(task: Task) {
        this._tasks.push(task);
        if (!this.processing) this.startNextTask();
    }

    private startNextTask() {
        this.ix++;
        if (this.ix >= this._tasks.length) {
            this.ix = this._tasks.length;
            this.triggerDone();
            return;
        }

        this.processing = true;
        this._tasks[this.ix]
            .onChange(task => {
                this.triggerOnStatus();
            })
            .execute()
            .then(() => {
                this.processing = false;
                this.startNextTask();
            });
    }

    private triggerOnStatus() {
        this.onStatusHandlers.forEach(handler => {
            handler({target: this});
        });
    }

    private triggerDone() {
        this.onDoneHandlers.forEach(handler => {
            handler({target: this});
        });
    }
}

export {TaskRunner, Task}