import {FunctionComponent, useState} from 'react';
import FormRow from '../form/form-row';
import Input from '../form/input';
import Button, {ButtonType} from '../common/button';

type LoginProps = {
    login: (username: string, password: string) => void
}

const Login: FunctionComponent<LoginProps> = ({login}) => {
    const [username, setUsername] = useState<string>('');
    const [password, setPassword] = useState<string>('');

    return (
        <form>
            <FormRow>
                <label>Username</label><br/>
                <Input value={username} onChange={val => setUsername(val)}/>
            </FormRow>
            <FormRow>
                <label>Password</label><br/>
                <Input type="password" value={password} onChange={val => setPassword(val)}/>
            </FormRow>
            <FormRow>
                <Button onClick={() => login(username, password)} type={ButtonType.Primary}>Let's Go</Button>
            </FormRow>
        </form>
    );
}

export default Login;
