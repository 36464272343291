import {FunctionComponent, PropsWithChildren} from 'react';
import './panel-content.css';

type PanelContentProps = PropsWithChildren;

const PanelContent: FunctionComponent<PanelContentProps> = ({children}) => {
    return (
        <div className="PanelContent">
            {children}
        </div>
    );
}

export default PanelContent;
