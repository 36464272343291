import React, {Component} from 'react';
import LoginPage from './pages/login';
import AppContext from './contexts/app-context';
import AuthUser from './models/auth-user';
import {Routes, Route, Navigate} from 'react-router-dom';
import api from './services/api';
import RacksPage from './pages/racks';
import LoadingIndicator from './components/common/loading-indicator';
import ModalManager, {Modal, StackChangeEvent} from './services/modal-manager';
import ModalStack from './components/modals/modal-stack';
import RackRoutes from './pages/racks/rack-page';

type AppState = {
    authUser?: AuthUser
    loaded: boolean
    modals: Array<Modal>
}

class App extends Component<void, AppState> {
    private modalManager: ModalManager;
    constructor() {
        super();
        this.modalManager = new ModalManager();
        this.state = {
            loaded: false,
            modals: []
        };
    }

    componentDidMount() {
        if (!this.state.loaded) {
            this.modalManager.onChange(this.handleModalChange.bind(this));
            api().auth.check().then(authUser => {
                this.setState({
                    authUser,
                    loaded: true
                })
            });
        }
    }

    render() {
        const state = this.state;
        return (
            <AppContext.Provider value={{
                authUser: this.state.authUser,
                modalManager: this.modalManager,
                setAuthUser: this.setAuthUser.bind(this)
            }}>
                <div className="App">
                    {state.loaded ? (
                        state.authUser ? (
                        <Routes>
                            <Route path="/racks" element={<RacksPage/>}/>
                            <Route path="/racks/:rackId" element={<RackRoutes/>}/>
                            <Route path="*" element={<Navigate to={"/racks"}/>}/>
                        </Routes>
                        ) : (
                            <LoginPage/>
                        )
                    ) : (
                        <>
                            <LoadingIndicator/> Loading App
                        </>
                    )}
                    <ModalStack stack={this.state.modals}/>
                </div>
            </AppContext.Provider>
        );
    }

    setAuthUser(authUser?: AuthUser) {
        this.setState({
            authUser
        })
    }

    handleModalChange(ev: StackChangeEvent) {
        this.setState({modals: ev.stack});
    }
}

export default App;
