class DistributionCode {
    constructor(private catId: string, private progId: string, private typeId: string, private planId: string ) {
    }
    public static parse(code: string) {
        const parts = code.split('-');
        if (parts.length !== 4) throw new Error('Invalid distribution code: ' + code);

        const [catId, progId, typeId, planId] = parts;

        return new DistributionCode(catId, progId, typeId, planId);
    }

    public toString() {
        return [this.catId, this.progId, this.typeId, this.planId].join('-');
    }
}

export default DistributionCode;
