import {FunctionComponent, useContext} from 'react';
import {Rack} from '../../types/rack';
import Button, {ButtonType} from '../common/button';
import {useNavigate} from 'react-router-dom';
import Tags from '../common/tags';
import Tag from '../common/tag';
import AppContext from '../../contexts/app-context';
import EditRackModal from '../racks/edit-rack-modal';
import {ModalOptions} from '../../services/modal-manager';
import {AppsIcon, LocationIcon} from '@primer/octicons-react';
import ButtonSet from '../common/button-set';

type DistributionConfigsTableProps = {
    deleteRackAtIndex: (ix: number) => Promise<void>
    racks: Array<Rack>
    refresh: () => void
};

const RacksTable: FunctionComponent<DistributionConfigsTableProps> = ({deleteRackAtIndex, racks, refresh}) => {
    const nav = useNavigate();
    const appContext = useContext(AppContext);
    return (
        <div>
            <table className="table">
                <thead>
                <tr>
                    <th>ID</th>
                    <th>Name</th>
                    <th>Distribution</th>
                    <th>User</th>
                    <th>&nbsp;</th>
                </tr>
                </thead>
                <tbody>
                {racks.map((rack, ix) => {
                    return <tr key={rack.id}>
                        <td>{rack.id}</td>
                        <td>
                            <AppsIcon/>
                            &nbsp;
                            <Button type={ButtonType.Link} onClick={() => nav('/racks/' + rack.id)}>{rack.name && rack.name.trim().length > 0 ? rack.name : 'Unnamed'}</Button>
                        </td>
                        <td>
                            <Tags>
                                {rack.codes.map(code => (
                                    <Tag key={code.toString()}><LocationIcon/> {code.toString()}</Tag>
                                ))}
                            </Tags>
                        </td>
                        <td>{rack.user ? rack.user.name : 'n/a'}</td>
                        <td>
                            <ButtonSet>
                                <Button onClick={() => {
                                    const modalOptions = new ModalOptions();
                                    modalOptions.canClickOutside = false;
                                    appContext.modalManager.pushModal(
                                        <EditRackModal rack={rack}
                                                       onDone={(wasChanged, rackId) => {
                                                           console.log('RacksTable.onDone:', wasChanged);
                                                           appContext.modalManager.popModal();
                                                           if (wasChanged) refresh();
                                                       }}
                                        />,
                                        modalOptions
                                    )
                                }}>Edit</Button>
                                <Button type={ButtonType.Danger} onClick={() => deleteRackAtIndex(ix)}>Delete</Button>
                            </ButtonSet>
                        </td>
                    </tr>
                })}
                </tbody>
            </table>
        </div>
    );
}

export default RacksTable;
