import {FunctionComponent, useState} from 'react';
import Modal from '../modals/modal';
import ModalContent from '../modals/modal-content';
import {SaveRack} from '../../types/rack';
import EditRack, {EditRackProps} from './edit-rack';

type EditRackModalProps = EditRackProps

const EditRackModal: FunctionComponent<EditRackModalProps> = ({
                                                                  rack,
                                                                  onDone
                                                              }) => {
    const [sessionRack] = useState<SaveRack>({
        enable: rack?.enable ?? true,
        name: rack?.name ?? '',
        note: rack?.note ?? '',
        id: rack?.id
    });

    return (
        <Modal title={
            rack ?
                (sessionRack.name.length === 0 ? 'Edit Rack' : sessionRack.name + ' (ID: ' + sessionRack.id + ')')
                :
                'Create Rack' + (sessionRack.name.length === 0 ? '' : ': ' + sessionRack.name + ' (ID: ' + sessionRack.id + ')')
        }>
            <ModalContent>
                <EditRack rack={rack}
                          onDone={onDone}
                />
            </ModalContent>
        </Modal>
    )
        ;
}

export default EditRackModal;
