import {FunctionComponent, PropsWithChildren} from 'react';
import './form-row.css';

type FormRowProps = PropsWithChildren;

const FormRow: FunctionComponent<FormRowProps> = ({children}) => {
    return (
        <div className="FormRow">
            {children}
        </div>
    );
}

export default FormRow;
