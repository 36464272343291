import {FunctionComponent} from 'react';
import './status-indicator.css';
import classNames from 'classnames';

enum Status {
    Initializing = 'Initializing',
    Busy = 'Busy',
    Failed = 'Failed',
    Done = 'Done'
}

type StatusIndicatorProps = {
    status: Status
}

const StatusIndicator: FunctionComponent<StatusIndicatorProps> = ({status}) => {
    return (
        <div className={classNames('StatusIndicator', status)}></div>
    );
}

export default StatusIndicator;
export {Status}