import {FunctionComponent} from 'react';
import RackSectionLayout from './rack-section-layout';
import {RackPocket} from '../../types/rack-pocket';
import './rack-layout.css';
import {Rack} from '../../types/rack';
import {RackSection} from '../../types/rack-section';

const RackLayout: FunctionComponent<RackProps> = ({
                                                      setPocket,
                                                      onMoveSectionUp,
                                                      onMoveSectionDown,
                                                      rack,
                                                      sections
                                                  }) => {
    return (
        <div className="Rack">
            {sections.map((section, sectionIx) => (
                <div key={sectionIx}>
                    <RackSectionLayout key={sectionIx}
                                       section={section}
                                       setPocket={(rowIx, colIx, pocket) => setPocket(sectionIx, rowIx, colIx, pocket)}
                                       onAddSection={() => {
                                       }}
                                       canMoveDown={sectionIx < sections.length - 1}
                                       canMoveUp={sectionIx > 0}
                                       onMoveSectionUp={() => onMoveSectionUp(sectionIx)}
                                       onMoveSectionDown={() => onMoveSectionDown(sectionIx)}
                    />
                </div>
            ))}
        </div>
    );
}

type SetPocketHandler = (sectionIx: number, rowIx: number, colIx: number, pocket?: RackPocket) => Promise<void>

type RackProps = {
    rack: Rack
    sections: Array<RackSection>
    setPocket: SetPocketHandler
    onMoveSectionUp: (sectionIx: number) => void
    onMoveSectionDown: (sectionIx: number) => void
};

export default RackLayout;
