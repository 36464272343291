import {CSSProperties, FunctionComponent, ReactElement} from 'react';
import './section.css';
import classNames from 'classnames';

type SectionProps = {
    children: ReactElement | Array<ReactElement>
    marginTop?: boolean | number
    marginBottom?: boolean | number
};

const Section: FunctionComponent<SectionProps> = ({
                                                      marginBottom,
                                                      marginTop,
                                                      children
                                                  }) => {
    const style: CSSProperties = {};
    if (typeof(marginTop) === 'number') style.marginTop = marginTop;
    if (typeof(marginBottom) === 'number') style.marginBottom = marginBottom;

    return <div className={classNames('Section', {
        'margin-top': marginTop === undefined || marginTop === true,
        'margin-bottom': marginBottom === undefined || marginBottom === true,
    })}>{children}</div>;
}

export default Section;
