import {FunctionComponent, PropsWithChildren, ReactNode} from 'react';

export type TabProps = PropsWithChildren & {
    icon?: ReactNode
    label: string
    disabled?: boolean
};

const Tab: FunctionComponent<TabProps> = ({children}) => {
    return (
        <div className="Tab">
            {children}
        </div>
    );
}

export default Tab;
