import React, {FunctionComponent, useContext} from 'react';
import {Modal} from '../../services/modal-manager';
import './modal-stack.css';
import AppContext from '../../contexts/app-context';

type ModalStackProps = {
    stack: Array<Modal>
};

const ModalStack: FunctionComponent<ModalStackProps> = ({stack}) => {
    const appContext = useContext(AppContext);
    if (stack.length === 0) return null;

    const currentModal = stack[stack.length-1];
    const showView = currentModal.view;

    return (
        <div className="Modals-overlay" onClick={() => {
            if (currentModal.options.canClickOutside) appContext.modalManager.popAll();
        }}>
            {showView}
        </div>
    );
};

export default ModalStack;
