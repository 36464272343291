import {FunctionComponent} from 'react';
import './input.css';

type InputProps = {
    id?: string
    onChange: (value: string) => void
    placeholder?: string
    type?: string
    value: string
};

const Input: FunctionComponent<InputProps> = ({
                                                  id,
                                                  onChange,
                                                  placeholder,
                                                  type,
                                                  value,
                                              }) => {
    if (type === 'textarea') return <textarea className="Input" value={value}
                                              onChange={e => onChange(e.target.value)}/>;

    return <input id={id}
                  type={type ? type : 'text'}
                  className="Input"
                  placeholder={placeholder}
                  value={value}
                  onChange={e => onChange(e.target.value)}/>;
}

export default Input;
