import BaseApi from './base-api';
import DistributionCode from '../../models/distribution-code';
import {Contract, ServerContract} from '../../types/contract';
import {AxiosError, AxiosRequestConfig, AxiosResponse} from 'axios';
import {SuccessErrorsResponse} from '../../types/success-errors-response';

type ContractsResponse = {
    contracts: Array<ServerContract>
}

type CacheStatus = {
    isCached: boolean
    isExpired: boolean
}

class ContractsApi extends BaseApi {
    checkCacheStatus(code: DistributionCode): Promise<CacheStatus> {
        const config: AxiosRequestConfig = {
            params: {
                code: code.toString()
            }
        };

        return this.api.get<CacheStatus>('/contracts/cache-status', config).then(response => response.data);
    }

    /**
     * Instructs API to synchronize the distribution code with Certified Folder
     * @param code
     */
    syncContracts(code: DistributionCode): Promise<SuccessErrorsResponse>
    {
        const config: AxiosRequestConfig = {
            params: {
                code: code.toString()
            }
        }

        return this.api.get<SuccessErrorsResponse>('/contracts/sync', config)
            .then(response => response.data)
            .catch(e => {
                if (e instanceof AxiosError) {
                    if (e.response?.status === 400 && e.response.data.errors) {
                        return e.response.data;
                    }
                }
                throw e;
            });
    }

    getContracts(codes: Array<DistributionCode>): Promise<Array<Contract>> {
        const config: AxiosRequestConfig = {
            params: {
                code: codes.map(code => code.toString()).join(',')
            }
        }
        return this.api.get<Array<Contract>, AxiosResponse<ContractsResponse>>('/contracts', config)
            .then(response => {
                return response.data.contracts.map(serverContract => {
                    let {contract, start, distribution, status, end, company, description, cust_id, revnbr, name, slsper_name, slsper_id} = serverContract;

                    const retContract: Contract = {
                        contract,
                        start: new Date(start),
                        distribution: distribution.map(code => DistributionCode.parse(code)),
                        status,
                        end: new Date(end),
                        company,
                        description,
                        cust_id,
                        revnbr,
                        name,
                        slsper_name,
                        slsper_id
                    }

                    return retContract;
                });
        });
    }
}

export default ContractsApi;
