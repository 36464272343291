import {FunctionComponent, useEffect, useState} from 'react';
import './rack-loader.css';
import StatusIndicator, {Status} from '../common/status-indicator';
import api from '../../services/api';
import {Task, TaskRunner} from '../../services/tasks';
import {Contract} from '../../types/contract';
import {Rack} from '../../types/rack';
import Panel from '../common/panel';
import PanelHeader from '../common/panel-header';
import PanelContent from '../common/panel-content';
import Button, {ButtonType} from '../common/button';

type RackLoaderProps = {
    rackId: number | undefined
    onLoad: (data: RackLoaderResult) => void
    onFail: () => void
}

type RackLoaderResult = {
    rack?: Rack
    contracts: Array<Contract>
}

const RackLoader: FunctionComponent<RackLoaderProps> = ({
                                                            rackId,
                                                            onLoad,
                                                            onFail
                                                        }) => {
    const [rack, setRack] = useState<Rack | undefined>();
    const [contracts, setContracts] = useState<Array<Contract>>([]);
    const [anyFailed, setAnyFailed] = useState<boolean>(false);
    const [tasks, setTasks] = useState<Array<Task>>([]);

    useEffect(() => {
        if (!rackId) return;
        const loader = new TaskRunner();
        let loaderRack: Rack;
        let loaderContracts: Array<Contract>;
        loader.onStatus(ev => {
            setTasks([...ev.target.tasks]);
        });

        loader.onDone(ev => {
            const anyTasksFailed = ev.target.tasks.filter(task => task.status === Status.Failed).length > 0;
            if (anyTasksFailed) {
                setAnyFailed(true);
            } else {
                onLoad({
                    rack: loaderRack,
                    contracts: loaderContracts
                })
            }
        });

        loader.addTask(new Task('Load rack', (task) => {
            task.status = Status.Busy;
            return api().racks.getRack(rackId).then(rack => {
                loaderRack = rack;
                setRack(rack);
                if (rack.codes.length === 0) {
                    task.status = Status.Failed;
                    loader.addTask(new Task('Click "Continue anyway" then "Configure" in the upper-right to add them', task => {
                        task.status = Status.Failed;
                        return Promise.resolve();
                    }));
                    return;
                }
                rack.codes.forEach(code => {
                    loader.addTask(new Task('Checking ' + code.toString(), (task => {
                        return api().contracts.checkCacheStatus(code).then(status => {
                            if (status.isCached && !status.isExpired) {
                                task.label = code.toString() + ' verified';
                                return;
                            }

                            task.label = 'Refreshing ' + code.toString();
                            return api().contracts.syncContracts(code).then(result => {
                                if (result.success) {
                                    task.label = code.toString() + ' synchronized';
                                } else {
                                    if (result.errors) task.label = task.label + ' (' + result.errors.join('; ') + ')';
                                    task.status = Status.Failed;
                                }
                            });
                        }).catch(e => {
                            task.label = task.label + ' (unable to retrieve: ' + e.message + ')';
                            task.status = Status.Failed;
                        });
                    })));
                });
                loader.addTask(new Task('Loading contracts', (task) => {
                    task.status = Status.Busy;
                    return api().contracts
                        .getContracts(rack.codes)
                        .then(resultContracts => {
                            loaderContracts = resultContracts;
                            setContracts(resultContracts);
                        });
                }));
                // api().contracts.getContracts(this.codes).then(contracts => {
            })
                .catch(e => {
                    task.label = task.label + ' (unable to retrieve)';
                    task.status = Status.Failed;
                    onFail();
                });
        }));

    }, [rackId, onFail, onLoad]);

    return (
        <div className="RackLoader">
            <Panel>
                <PanelHeader>{rack ? 'Loading ' + rack.name : 'Waiting for Rack (' + rackId + ')'}</PanelHeader>
                <PanelContent>
                    {tasks.length === 0 ? <>No tasks</> : (
                        tasks.map(task => {
                            return <div key={task.label + task.status}>
                                <StatusIndicator status={task.status}/> {task.label}
                            </div>
                        })
                    )}
                    {anyFailed ? (
                        <>
                            <hr/>
                            <p style={{color: 'red'}}>
                                One or more tasks failed, which could affect your experience. You may want to contact
                                support.
                            </p>
                            <Button onClick={() => {
                                onLoad({
                                    rack,
                                    contracts
                                });
                            }} type={ButtonType.Danger}>Continue anyway</Button>
                        </>
                    ) : null}
                </PanelContent>
            </Panel>
        </div>
    );
}
//
// type StepsProps = {
//     steps: Array<{label: string, done: boolean}>
// }
// const Steps: FunctionComponent<StepsProps> = ({steps}) => {
//     return (
//         <div className="Steps">
//             {steps.map(step => (
//                 <div>
//             ))}
//         </div>
//     )
// };

export default RackLoader;
