import {FunctionComponent, PropsWithChildren} from 'react';
import './rack-loader-screen.css';

type RackLoaderScreenProps = PropsWithChildren;

const RackLoaderScreen: FunctionComponent<RackLoaderScreenProps> = ({children}) => {
    return (
        <div className="RackLoaderScreen">
            <div className="RackLoaderScreen-content">
                {children}
            </div>
        </div>
    );
}

export default RackLoaderScreen;
