import {FunctionComponent, PropsWithChildren} from 'react';
import './pocket-option.css';

type PocketOptionProps = PropsWithChildren & {
    onClick: () => void
}

const PocketOption: FunctionComponent<PocketOptionProps> = ({
                                                                children,
                                                                onClick
                                                            }) => {
    return <div className="PocketOption" onClick={() => onClick()}>
        {children}
    </div>;
}

export default PocketOption;
