import {CSSProperties, FunctionComponent, MouseEventHandler, ReactElement} from 'react';
import './draggable.css';
import DragDropManager, {DraggingEvent} from './manager';
import classNames from 'classnames';

type DraggableProps<T> = {
    bucket?: string
    item: T
    children: (item: T) => ReactElement
    onDragCancelled?: () => void
    onDragStart?: (ev: DraggingEvent) => void
    onDrag?: (ev: DraggingEvent) => void
    onDragEnd?: (ev: DraggingEvent) => void
    className?: string
    style?: CSSProperties
}

const Draggable: FunctionComponent<DraggableProps<any>> = ({
                                                               bucket,
                                                               className,
                                                               item,
                                                               children,
                                                               onDrag,
                                                               onDragCancelled,
                                                               onDragEnd,
                                                               onDragStart,
                                                               style
                                                           }) => {
    const handleMouseDown: MouseEventHandler<HTMLDivElement> = (ev) => {
        function handleBodyKeyPress(ev: KeyboardEvent) {
            if (ev.key === 'Escape') {
                // if (onDragEnd) onDragEnd(DragDropManager.instance().createDragEvent(ev));
                DragDropManager.instance().setContext();
                draggingContainer.remove();
                document.removeEventListener('mousemove', handleMouseMove);
                document.removeEventListener('mouseup', handleMouseUp);
                document.removeEventListener('keydown', handleBodyKeyPress);
                if (onDragCancelled) onDragCancelled();
            }
        }

        DragDropManager.instance().setContext({
            bucket,
            item
        })
        const draggingContainer = document.createElement('div');
        draggingContainer.appendChild(ev.currentTarget.cloneNode(true));
        draggingContainer.style.position = 'absolute';
        // draggingContainer.style.left = (ev.pageX - draggingContainer.offsetWidth/2) + 'px';
        // draggingContainer.style.top = (ev.pageY - draggingContainer.offsetHeight/2) + 'px'
        draggingContainer.style.zIndex = '' + 10_000;
        document.body.appendChild(draggingContainer);


        if (onDragStart) onDragStart(DragDropManager.instance().createDragEvent(ev.nativeEvent));

        const handleMouseMove: (ev: any) => void = (ev: any) => {
            //
            draggingContainer.style.left = (ev.clientX - draggingContainer.offsetWidth / 2) + 'px';
            draggingContainer.style.top = (ev.clientY - draggingContainer.offsetHeight / 2) + 'px';
            draggingContainer.style.pointerEvents = 'none';
            if (onDrag) onDrag(DragDropManager.instance().createDragEvent(ev));
        }

        const handleMouseUp: (ev: MouseEvent) => void = (ev) => {
            if (onDragEnd) onDragEnd(DragDropManager.instance().createDragEvent(ev));
            DragDropManager.instance().setContext();
            document.removeEventListener('mousemove', handleMouseMove);
            document.removeEventListener('mouseup', handleMouseUp);
            document.removeEventListener('keydown', handleBodyKeyPress);
            draggingContainer.remove();
        }
        document.addEventListener('keydown', handleBodyKeyPress);
        document.body.addEventListener('mousemove', handleMouseMove);
        document.body.addEventListener('mouseup', handleMouseUp);
    }

    return (
        <div onMouseDown={handleMouseDown} className={classNames('Draggable', className)} style={style}>
            {children(item)}
        </div>
    );
}

export default Draggable;