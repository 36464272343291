import BaseApi from './base-api';
import AuthUser from '../../models/auth-user';
import AccessLevel from '../../enums/access-level';

type LoginRequest = {
    username: string
    password: string
}

type LoginResponse = {
    success: boolean
    userId?: number
    access?: string
    error?: string
}

type LoginCheck = {
    loggedIn: boolean
    userId?: number
    username?: string
    access?: string
}

class AuthApi extends BaseApi {
    logout(): Promise<void> {
        return this.api.get('/auth/logout').then(() => {});
    }

    login(username: string, password: string): Promise<AuthUser> {
        return this.api.post<LoginResponse, LoginRequest>('/auth/login', {username, password})
            .then(response => {
                if (!response.data.success) {
                    throw new Error(response.data.error);
                }

                const authUser: AuthUser = {
                    access: this.serverAccessLevelToAccessLevel(response.data.access!),
                    username: username,
                    userId: response.data.userId!
                };

                return authUser;
            });
    }

    check(): Promise<AuthUser | undefined> {
        return this.api.get<LoginCheck>('/auth/check')
            .then(result => result.data)
            .then(result => {
                if (result.loggedIn && result.username && result.access && result.userId) {
                    return new AuthUser(result.username, this.serverAccessLevelToAccessLevel(result.access), result.userId)
                }
            });
    }

    serverAccessLevelToAccessLevel(accessLevel: string): AccessLevel {
        switch(accessLevel) {
            case 'E':
                return AccessLevel.EXECUTIVE;
            case 'O':
                return AccessLevel.OVERRIDE;
            default:
                return AccessLevel.STANDARD;
        }
    }
}

export default AuthApi;
