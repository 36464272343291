import {FunctionComponent} from 'react';
import classNames from 'classnames';
import './code-boxes.css';

type CodeBoxesProps = {
    catId?: string
    progId?: string
    typeId?: string
    planId?: string
    showMissing?: boolean
};

const CodeBoxes: FunctionComponent<CodeBoxesProps> = ({
                                                          catId,
                                                          progId,
                                                          typeId,
                                                          planId,
                                                          showMissing
                                                      }) => {
    return (
        <div className="CodeBoxes">
            <div className={classNames('CodeBoxes-label', {invalid: showMissing === true && !catId})}>Cat:</div>
            <div
                className={classNames('CodeBoxes-value', {invalid: showMissing === true && !catId})}>{catId ? catId : 'n/a'}</div>
            <div className={classNames('CodeBoxes-label', {invalid: showMissing === true && !progId})}>Prog:</div>
            <div
                className={classNames('CodeBoxes-value', {invalid: showMissing === true && !progId})}>{progId ? progId : 'n/a'}</div>
            <div className={classNames('CodeBoxes-label', {invalid: showMissing === true && !typeId})}>Type:</div>
            <div
                className={classNames('CodeBoxes-value', {invalid: showMissing === true && !typeId})}>{typeId ? typeId : 'n/a'}</div>
            <div className={classNames('CodeBoxes-label', {invalid: showMissing === true && !planId})}>Plan:</div>
            <div
                className={classNames('CodeBoxes-value', {invalid: showMissing === true && !planId})}>{planId ? planId : 'n/a'}</div>
        </div>
    );
}

export default CodeBoxes;
