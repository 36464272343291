import {FunctionComponent, PropsWithChildren} from 'react';
import './tab-content.css';

type TabContentProps = PropsWithChildren;

const TabContent: FunctionComponent<TabContentProps> = ({children}) => {
    return <div className="TabContent">
        {children}
    </div>;
}

export default TabContent;
