export type DragContext = {
    item?: any
    bucket?: string
    // index?: number
}

export type DraggingEvent <T = any> = {
    item: T
    nativeEvent: MouseEvent
}

class DragDropManager {
    private static _instance: DragDropManager;
    private context?: DragContext;

    setContext(context?: DragContext) {
        this.context = context;
    }

    getContext(): DragContext | undefined {
        return this.context;
    }

    createDragEvent(ev: Event): DraggingEvent {
        return {
            item: this.context?.item,
            nativeEvent: ev
        } as DraggingEvent
    }

    static instance() {
        if (!DragDropManager._instance) {
            DragDropManager._instance = new DragDropManager();
        }
        return DragDropManager._instance;
    }
}

export default DragDropManager