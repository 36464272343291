import {FunctionComponent, PropsWithChildren} from 'react';
import {Routes, Route, Navigate} from 'react-router-dom';
import PlacementPage from '../placement';

type RackProps = PropsWithChildren;

const RackPage: FunctionComponent<RackProps> = (props) => {
    return (
        <Routes>
            <Route path="/" element={<PlacementPage/>}/>
            <Route path="*" element={<Navigate to="/"/>}/>
        </Routes>
    );
}

export default RackPage;
