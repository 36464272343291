import {FunctionComponent, PropsWithChildren} from 'react';
import './menu-bar.css';

type MenuBarProps = PropsWithChildren;

const MenuBar: FunctionComponent<MenuBarProps> = ({children}) => {

    return <div className="MenuBar">{children}</div>;
}

export default MenuBar;
