import {FunctionComponent, ReactNode, useContext, useState} from 'react';
import Droppable from '../drag-drop/droppable';
import classNames from 'classnames';
import {RackPocket} from '../../types/rack-pocket';
import PocketOptions from './pocket-options';
import PocketOption from './pocket-option';
import DragDropManager, {DraggingEvent} from '../drag-drop/manager';
import PlacementContext from '../../contexts/placement-context';
import './pocket-cell.css';
import AppContext from '../../contexts/app-context';
import Modal from '../modals/modal';
import ModalContent from '../modals/modal-content';
import EditPocket from './edit-pocket';
import {AxiosError} from 'axios';
import moment from 'moment';

type PocketProps = {
    column: number
    setPocket: (pocket?: RackPocket) => Promise<void>
    pocket: RackPocket | undefined
    row: number
    sectionId: number
    width: number // percent
};

enum PocketStatus {
    Normal,
    Saving,
    Error
}

function maxLenString(text: string, len: number, appendOnChopped: string='') {
  let t = text;
  if (t.length > len) t = t.substring(0, len+appendOnChopped.length) + appendOnChopped;
  return t;
}

function displayDateRange(start: Date, end: Date): ReactNode
{
  let startDate = moment(start);
  let endDate = moment(end);
  return startDate.format('M/D') + '-' + endDate.format('M/D/YY');
}

const PocketCell: FunctionComponent<PocketProps> = ({
                                                        column,
                                                        pocket,
                                                        row,
                                                        sectionId,
                                                        setPocket,
                                                        width,
                                                    }) => {
    const [isHovering, setIsHovering] = useState<boolean>(false);
    const [isMouseOver, setIsMouseOver] = useState<boolean>(false);
    const [showOptions, setShowOptions] = useState<boolean>(false);
    const [pocketStatus, setPocketStatus] = useState<PocketStatus>(PocketStatus.Normal);
    const placementContext = useContext(PlacementContext);
    const appContext = useContext(AppContext);

    const isDuplicate = pocket &&
        pocket.contract &&
        placementContext.contractPositions[pocket.contract] !== undefined &&
        placementContext.contractPositions[pocket.contract].filter(position => {
            return !(position.sectionId === sectionId && position.posX === pocket.pos_x && position.posY === pocket.pos_y);
        }).length > 0;

    const isExpired = pocket && pocket.end && pocket.end.getTime() < Date.now();
    return <td width={width + '%'}
               className={classNames(
                   'racks-PocketCell',
                   {
                       hovering: isHovering,
                       duplicate: isDuplicate,
                       clickable: true,//pocket !== undefined,
                       saving: pocketStatus === PocketStatus.Saving,
                       error: pocketStatus === PocketStatus.Error,
                       highlight: pocket && pocket.contract === placementContext.checkDuplicateContract && !isHovering && (!isMouseOver || isDuplicate),
                     expired: isExpired,
                   }
               )}
               onClick={ev => {
                   function handleBodyClick(evBody: MouseEvent) {
                       setShowOptions(false);
                       document.body.removeEventListener('click', handleBodyClick);
                   }

                   setShowOptions(!showOptions);
                   if (!showOptions) {
                       setTimeout(() => {
                           document.body.addEventListener('click', handleBodyClick);
                       }, 50);
                   }
               }}
               onMouseOver={() => {
                   if (DragDropManager.instance().getContext() !== undefined) return;

                   // if (timer.current) window.clearTimeout(timer.current);
                   // timer.current = window.setTimeout(() => {
                   if (pocket?.contract) placementContext.showDuplicateContract(pocket.contract);
                   setIsMouseOver(true);
                   // setShowOptions(true);
                   // }, 100);
               }}
               onMouseOut={() => {
                   // if (showOptions) setShowOptions(false);
                   placementContext.showDuplicateContract();
                   setIsMouseOver(false);
               }}
    >
        {pocket && pocket.is_custom && <div className="racks-PocketCell-custom">Custom</div>}
        {isExpired && <div className="racks-PocketCell-expired"><div className="racks-PocketCell-expired-desc">Expired</div></div>}
      <Droppable className={classNames('racks-PocketCell-droppable')}
                   onDrop={(ev: DraggingEvent<RackPocket>) => {
                       const pocket = {
                           ...ev.item,
                           pos_x: column,
                           pos_y: row
                       };
                       setPocketStatus(PocketStatus.Saving);
                       setPocket(pocket).then(() => {
                           setPocketStatus(PocketStatus.Normal);
                       }).catch(e => {
                           setPocketStatus(PocketStatus.Error);
                           if (e instanceof AxiosError && e.response?.status === 401) {
                               alert('An error occurred while saving.  You may have been logged out.  Try refreshing this page. Server error: ' + e.message);
                           } else {
                               alert('Unknown error: ' + e.message);
                           }
                       });
                       setIsHovering(false);
                   }}
                   onDragOver={() => setIsHovering(true)}
                   onDragOut={() => setIsHovering(false)}
        >
            {pocket === undefined ? '' : (
                pocketStatus === PocketStatus.Saving ?
                    <>Saving...</>
                    :
                    <>
                      <div className="name">{maxLenString(pocket!.name, 20, '...')}</div>
                      {pocket && <div className="contract">{pocket?.contract}</div>}
                      {pocket && pocket.start && pocket.end && (
                        <div className="dates">
                          {displayDateRange(pocket.start, pocket.end)}
                        </div>
                      )}
                    </>
            )}
            {showOptions /* && !isHovering */ ? (
                <div className="racks-PocketCell-options">
                    <div className="racks-PocketCell-options-info">
                      <p>{pocket?.name}</p>
                      {pocket && pocket.start && pocket.end && (
                        <p><small>Start:</small><br/>
                          {pocket.start.toLocaleDateString()}<br/>
                          <small>End:</small><br/>
                          {pocket.end.toLocaleDateString()}</p>
                        )}
                    </div>
                    <PocketOptions>
                      {/*{pocket && pocket.start && pocket.end && (*/}
                      {/*  <>*/}
                      {/*    Start: {pocket.start.toLocaleDateString()}<br/>*/}
                      {/*    End: {pocket.end.toLocaleDateString()}*/}
                      {/*  </>*/}
                      {/*)}<br/>*/}
                        <PocketOption onClick={() => setShowOptions(false)}>Close</PocketOption>
                        {pocket ? (
                                <PocketOption onClick={() => {
                                    setPocketStatus(PocketStatus.Saving);
                                    setPocket().then(() => {
                                        setPocketStatus(PocketStatus.Normal);
                                    }).catch(e => {
                                        setPocketStatus(PocketStatus.Error);
                                        alert('Unknown error: ' + e.message);
                                    });
                                }}>Delete</PocketOption>
                            )
                            :
                            null
                        }
                        {!pocket || pocket.is_custom ? (
                            <PocketOption onClick={() => {
                                appContext.modalManager.pushModal(
                                    <Modal title="Customize Pocket">
                                        <ModalContent>
                                            <EditPocket row={row}
                                                        column={column}
                                                        pocket={pocket}
                                                        setPocket={setPocket}
                                                        onDone={() => appContext.modalManager.popModal()}
                                            />
                                        </ModalContent>
                                    </Modal>
                                );
                            }}>Edit</PocketOption>
                        ) : null}
                    </PocketOptions>
                </div>
            ) : null}
        </Droppable>

    </td>
}

export default PocketCell;
