import {FunctionComponent, PropsWithChildren, useContext, useState} from 'react';
import './login.css';
import AppContext from '../contexts/app-context';
import Login from '../components/login/login';
import LoadingIndicator from '../components/common/loading-indicator';
import api from '../services/api';
import ErrorList from '../components/common/error-list';
import Panel from '../components/common/panel';
import PanelContent from '../components/common/panel-content';
import PanelHeader from '../components/common/panel-header';

type LoginPageProps = PropsWithChildren;

type LoadingStatus = {
    busy: boolean
    message: string
}
const LoginPage: FunctionComponent<LoginPageProps> = (props) => {
    const [loadingStatus, setLoadingStatus] = useState<LoadingStatus>({busy: false, message: ''})
    const appContext = useContext(AppContext);

    return (
        <div className="LoginPage flex h-center v-center">
            <div>
                <Panel>
                    <PanelHeader>
                        RLS Login
                    </PanelHeader>
                    <PanelContent>
                        {/*<div className="LoginPage-box">*/}
                            {loadingStatus.busy ? (
                                <><LoadingIndicator/> {loadingStatus.message}</>
                            ) : (
                                <>
                                    <ErrorList errors={loadingStatus.message.length > 0 ? [loadingStatus.message] : []}/>
                                    <Login login={(username, password) => {
                                        setLoadingStatus({
                                            busy: true,
                                            message: ''
                                        });
                                        api().auth.login(username, password)
                                            .then(authUser => {
                                                setLoadingStatus({
                                                    busy: false,
                                                    message: 'Success'
                                                });
                                                appContext.setAuthUser(authUser);
                                            })
                                            .catch(e => {
                                                setLoadingStatus({
                                                    busy: false,
                                                    message: e.message
                                                })
                                            });
                                    }}/>
                                </>
                            )}
                        {/*</div>*/}
                    </PanelContent>
                </Panel>
            </div>
        </div>
    )
}

export default LoginPage;
