import {FunctionComponent} from 'react';
import {indexToAlpha} from '../../utils/index-to-alpha';
import PocketCell from './pocket-cell';
import {RackSection as RackSectionType} from '../../types/rack-section';
import {RackPocket} from '../../types/rack-pocket';

const RackSectionGrid: FunctionComponent<RackSectionGridProps> = ({
                                                                      setPocket,
                                                                      section
                                                                  }) => {
    const headers = [];
    for (let c = 0; c < section.cols; c++) {
        headers.push(<th key={c}>{indexToAlpha(c)}</th>);
    }
    const grid = pocketsToGrid(section);
    const width = Math.round(99 / section.cols * 100) / 100;
    return (
        <table className="racks-SectionTable" width="100%">
            <thead>
            <tr>
                <td>&nbsp;</td>
                {headers}
            </tr>
            </thead>
            <tbody>
            {grid.map(
                (row, rowIx) => {
                    const label = rowIx + 1; //String.fromCharCode(65 + rowIx);
                    return <tr key={rowIx}>
                        <th>{label}</th>
                        {row.map((pocket, colIx) => {
                            return <PocketCell key={colIx}
                                               sectionId={section.id}
                                               row={rowIx}
                                               column={colIx}
                                               width={width}
                                               pocket={grid[rowIx][colIx]}
                                               setPocket={pocket => {
                                                   return setPocket(rowIx, colIx, pocket);
                                               }}
                            />
                        })}
                    </tr>
                })}
            </tbody>
        </table>
    );
}

function pocketsToGrid(section: RackSectionType): RackGrid {
    const grid: RackGrid = [];
    for (let r = 0; r < section.rows; r++) {
        grid[r] = [];
        for (let c = 0; c < section.cols; c++) {
            grid[r][c] = undefined;
        }
    }

    section.pockets.forEach((pocket, pocketIx) => {
        grid[pocket.pos_y][pocket.pos_x] = pocket;
    });

    return grid;
}

// type RackGridPocketType = {
//     pocketIx: number
//     pocket?: RackPocket
// }

type RackGrid = Array<Array<RackPocket | undefined>>

type RackSectionGridProps = {
    section: RackSectionType
    setPocket: SetPocketHandler
}

type SetPocketHandler = (rowIx: number, colIx: number, pocket?: RackPocket) => Promise<void>

export default RackSectionGrid;
