import {FunctionComponent, PropsWithChildren} from 'react';
import './panel-header.css';

type PanelHeaderProps = PropsWithChildren;

const PanelHeader: FunctionComponent<PanelHeaderProps> = ({children}) => {
    return (
        <div className="PanelHeader">
            {children}
        </div>
    );
}

export default PanelHeader;
