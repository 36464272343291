import {FunctionComponent, PropsWithChildren} from 'react';
import './input-group.css';

type InputGroupProps = PropsWithChildren;

const InputGroup: FunctionComponent<InputGroupProps> = ({children}) => {
    return (
        <div className="InputGroup">
            {children}
        </div>
    );
}

export default InputGroup;
