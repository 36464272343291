import {FunctionComponent, Children, ReactElement, useState} from 'react';
import './tabs.css';
import {TabProps} from './tab';
import classNames from 'classnames';

type TabsProps = {
    children: ReactElement<TabProps> | Array<ReactElement<TabProps>>
    initialTab?: string // Supply the tab label (name) to default selection
    tabIx?: number
    onTabSelected?: (ev: TabSelectedEvent) => void
    actions?: Array<ReactElement> | ReactElement
};

type TabSelectedEvent = {
    tabIx: number
    label: string
}

enum TabManagement {
    State = 'State',
    External = 'External'
}

/**
 * Tabs can be locally by STATE or externally by supplying onTabSelected AND tabIx properties
 * @param children
 * @param onTabSelected
 * @param tabIx
 * @constructor
 */
const Tabs: FunctionComponent<TabsProps> = ({
                                                actions,
                                                children,
                                                initialTab,
                                                onTabSelected,
                                                tabIx
                                            }) => {
    let initialTabIx = initialTab ?
        Children.map(children, child => child).findIndex(child => child.props.label === initialTab)
        :
        0;
    if (initialTabIx < 0) initialTabIx = 0;
    const [localTabIx, setLocalTabIx] = useState<number>(initialTabIx);
    let tabManagement: TabManagement;
    let tabsMisconfigured = false;

    if (onTabSelected !== undefined && tabIx !== undefined) {
        tabManagement = TabManagement.External;
    } else {
        if (tabIx) tabsMisconfigured = true;
        tabManagement = TabManagement.State;
    }

    if (tabsMisconfigured) {
        return <div>Tabs are misconfigured. When tabIx is set as a prop then onTabSelected must also be set.</div>
    }

    const selectedTabIx = tabManagement === TabManagement.External && tabIx ? tabIx : localTabIx;

    return (
        <div className="Tabs">
            <div className="Tabs-tabs">
                {Children.map(children, (child, ix) => {
                    return (
                        <div className={classNames('Tabs-button', {
                            active: ix === selectedTabIx,
                            disabled: child.props.disabled === true
                        })}
                             onClick={() => {
                                 if (child.props.disabled === true) return;
                                 if (onTabSelected) {
                                     onTabSelected({
                                         tabIx: ix,
                                         label: child.props.label
                                     });
                                 }
                                 if (tabManagement === TabManagement.State) {
                                     setLocalTabIx(ix)
                                 }
                             }}>
                            {child.props.icon ? <>{child.props.icon} </> : null}
                            {child.props.label}
                        </div>
                    )
                })}
            </div>
            <div className="Tabs-content">
                {Children.map(children, (child, ix) => (
                    <div className={classNames('Tabs-tab', {active: ix === selectedTabIx})}>
                        {child}
                    </div>
                ))}
            </div>
        </div>
    );
}

export default Tabs;
