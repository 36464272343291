import {createContext} from 'react';
import AuthUser from '../models/auth-user';
import ModalManager from '../services/modal-manager';

type AppContextProps = {
    authUser?: AuthUser
    setAuthUser: (authUser?: AuthUser) => void
    modalManager: ModalManager
};

const AppContext = createContext<AppContextProps>({
    setAuthUser: (authUser?: AuthUser) => {},
    modalManager: new ModalManager()
});

export default AppContext;
