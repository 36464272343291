import {FunctionComponent, useCallback, useEffect, useState} from 'react';
import api from '../../services/api';
import LoadingIndicator from '../common/loading-indicator';
import {Status} from '../common/status-indicator';
import ErrorList from '../common/error-list';
import DistributionCode from '../../models/distribution-code';
import {RackCodeResponse} from '../../types/server/rack-code-response';
import Button, {ButtonType} from '../common/button';
import Input from '../form/input';
import CodeBoxes from '../distribution-list/code-boxes';
import {AxiosError} from 'axios';
import update from 'immutability-helper';
import {LocationIcon, TrashIcon} from '@primer/octicons-react';
import FormRow from '../form/form-row';

type EditRackCodesProps = {
    rackId: number
    onChanged: () => void
    commitLabel: string
    commit: () => void
};

type EditRackCodeState = {
    status: Status
    errors: Array<string>
    editingCodeIx?: number
}

const EditRackCodes: FunctionComponent<EditRackCodesProps> = ({onChanged, rackId, commit, commitLabel}) => {
    const [state, setState] = useState<EditRackCodeState>({
        status: Status.Initializing,
        errors: []
    });
    const [sessionCode, setSessionCode] = useState<string>('');
    const [codes, setCodes] = useState<Array<RackCodeResponse>>([]);
    // const [editingSection, setEditingSection] = useState<{ix: number, section: SaveRackSection} | undefined>();

    const loadCodes = useCallback(() => {
        api().racks.getCodes(rackId)
            .then(codes => {
                setCodes(codes);
                setState({status: Status.Done, errors: []});
            });
    }, [rackId]);

    useEffect(() => {
        loadCodes();
    }, [rackId, loadCodes]);

    const codeValid = sessionCode.match(/[^-]+-[^-]+-[^-]+-[^-]+/);
    const [catId, progId, typeId, planId] = sessionCode.split('-');

    const addCode = (): Promise<boolean> => {
        setState({status: Status.Busy, errors: [], editingCodeIx: undefined});

        return api().racks.addCode(rackId, new DistributionCode(catId, progId, typeId, planId))
            .then(code => {

                onChanged();
                setSessionCode('');
                setState(prevState => ({...prevState,
                    status: Status.Done
                }));
                setCodes(update(codes, {
                    $push: [code]
                }));
                return true;
            })
            .catch(e => {

                let errors;
                if (e instanceof AxiosError && e.response?.data.errors) {
                    errors = e.response.data.errors;
                } else {
                    errors = ['Unknown error: ' + e.message];
                }
                setState({status: Status.Failed, errors});
                return false;
            });
    };

    return (
        <div>
            <ErrorList errors={state.errors}/>
            {codes === undefined ? (
                <><LoadingIndicator/> Loading</>
            ) : (
                <>
                    {state.status === Status.Busy && state.editingCodeIx === undefined ? <div><LoadingIndicator /> Saving</div> : null}
                    {codes.length === 0 ? <p><strong>Add your first distribution code: </strong></p> : null}
                    <div>
                        Distribution Code:
                        &nbsp;
                        <Input value={sessionCode}
                               onChange={val => setSessionCode(val.toUpperCase())}
                               placeholder="xxx-xxx-xxx-xx/xx"/>
                        &nbsp;
                        <Button type={ButtonType.Primary}
                                disabled={!codeValid || state.status === Status.Busy}
                                    onClick={() => {
                                        addCode();
                                        // setState({status: Status.Busy, errors: [], editingCodeIx: undefined});
                                        // api().racks.addCode(rackId, new DistributionCode(catId, progId, typeId, planId))
                                        //     .then(code => {
                                        //         onChanged();
                                        //         setState(prevState => ({...prevState,
                                        //             status: Status.Done
                                        //         }));
                                        //         setCodes(update(codes, {
                                        //             $push: [code]
                                        //         }));
                                        //     })
                                        //     .catch(e => {
                                        //         let errors;
                                        //         if (e instanceof AxiosError && e.response?.data.errors) {
                                        //             errors = e.response.data.errors;
                                        //         } else {
                                        //             errors = ['Unknown error: ' + e.message];
                                        //         }
                                        //         setState({status: Status.Failed, errors});
                                        //     });
                                    }}>Add</Button>
                            &nbsp;
                        &nbsp;
                        {sessionCode.length > 0 ? <CodeBoxes catId={catId} progId={progId} typeId={typeId} planId={planId} showMissing={true}/> : null}
                    </div>
                    {codes.length === 0 ? null : (
                        <>
                            <p><strong>Codes:</strong></p>
                            <table className="table">
                                <tbody>
                                {codes.map((code, codeIx) => {
                                    const distCode = new DistributionCode(code.cat_id, code.prog_id, code.type_id, code.plan_id);
                                    return (
                                        <tr key={distCode.toString()} style={state.status === Status.Busy && codeIx === state.editingCodeIx ? {backgroundColor: '#fdd'} : {}}>
                                            <td>
                                                {state.status === Status.Busy && codeIx === state.editingCodeIx ? <LoadingIndicator/> : null}
                                                <LocationIcon/>
                                                {distCode.toString()}</td>
                                            <td><Button type={ButtonType.Danger}
                                                        onClick={() => {
                                                            setState({status: Status.Busy, errors: [], editingCodeIx: codeIx});
                                                            onChanged();
                                                            api().racks.deleteCode(rackId, code.id).then(() => {
                                                                setState(prevState => ({
                                                                    ...prevState,
                                                                    status: Status.Done,
                                                                    editingCodeIx: undefined
                                                                }));
                                                                setCodes(update(codes, {
                                                                    $splice: [[codeIx, 1]]
                                                                }));
                                                            }).catch(e => {
                                                                let errors: Array<string>;
                                                                if (e instanceof AxiosError && e.response?.data.errors) errors = e.response.data.errors;
                                                                else errors = ['An unknown error occurred: ' + e.message];

                                                                setState(prevState => ({
                                                                    status: Status.Failed,
                                                                    errors,
                                                                    editingCodeIx: undefined
                                                                }));
                                                            });
                                                        }}
                                            >
                                                <TrashIcon/>
                                            </Button>
                                            </td>
                                        </tr>
                                    )
                                        ;
                                })}
                                </tbody>
                            </table>
                        </>
                    )}
                </>
            )}
            <FormRow>
                {codes.length > 0 && <Button onClick={() => {
                    const doSave = sessionCode.length === 0 ? Promise.resolve(true) : addCode();
                    doSave.then(success => {
                        if (!success) return;
                        commit()
                    });
                }}>
                    {commitLabel}
                </Button>}
            </FormRow>
        </div>
    );
}

export default EditRackCodes;
