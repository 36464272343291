import {FunctionComponent, useState} from 'react';
import {RackPocket} from '../../types/rack-pocket';
import Input from '../form/input';
import Button, {ButtonType} from '../common/button';
import FormRow from '../form/form-row';
import InputGroup from '../form/input-group';
import ButtonSet from '../common/button-set';
// import './edit-pocket.css';
import StatusIndicator, {Status} from '../common/status-indicator';
import ErrorList from '../common/error-list';

type EditPocketProps = {
    row: number
    column: number
    pocket?: RackPocket
    setPocket: (pocket: RackPocket) => Promise<void>
    onDone: () => void
};

const EditPocket: FunctionComponent<EditPocketProps> = ({
                                                            pocket,
                                                            setPocket,
                                                            onDone
                                                        }) => {
    const [name, setName] = useState<string>(pocket ? pocket.name : '');
    const [status, setStatus] = useState<Status>(Status.Initializing);
    const [errors, setErrors] = useState<Array<string>>([]);
    function handleSave() {
        let newPocket;
        if (pocket) {
            newPocket = {...pocket, name};
        } else {
            newPocket = {
                name,
                is_custom: true,
                pos_y: -1, // These will be updated
                pos_x: -1
            };
        }
        setStatus(Status.Busy);
        setPocket(newPocket).then(() => {
            setStatus(Status.Done);
        }).catch(e => {
            setStatus(Status.Failed);
            setErrors([e.message]);
        });
    }

    if (status === Status.Done) {
        setTimeout(() => {
            onDone();
        }, 1000);
        return <div>Saved!</div>
    }

    return (
        <div className="EditPocket">
            <ErrorList errors={errors}/>
            <FormRow>
                <InputGroup>
                    {pocket?.name}
                    Name: <Input value={name} onChange={val => setName(val)}/>
                </InputGroup>
            </FormRow>
            <ButtonSet>
                <Button onClick={() => handleSave()} type={ButtonType.Primary}>
                    {status === Status.Busy || status === Status.Failed ? <StatusIndicator status={status}/> : null}
                    {' '}
                    Save
                </Button>
                <Button onClick={() => onDone()} type={ButtonType.Danger}>Cancel</Button>
            </ButtonSet>
        </div>
    );
}

export default EditPocket;
