import {FunctionComponent, useState} from 'react';
import {Rack} from '../../types/rack';
import {RackUser} from '../../types/rack-user';
import Tags from '../common/tags';
import Tag from '../common/tag';
import Button, {ButtonType} from '../common/button';
import {useNavigate} from 'react-router-dom';
import {LocationIcon} from '@primer/octicons-react';
import ButtonSet from '../common/button-set';

type CodeListProps = {
    racks: Array<Rack>
    deleteRackAtIndex: (ix: number) => Promise<void>
};

const UserCodesTable: FunctionComponent<CodeListProps> = ({deleteRackAtIndex, racks}) => {
    const [selectedCode, setSelectedCode] = useState<string | void>();
    const rackIndexesByCode: { [code: string]: Array<number> } = {};
    const usersByCode: { [code: string]: Array<RackUser> } = {};
    const codes: Array<string> = [];

    racks.forEach((rack, rackIx) => {
        rack.codes.forEach(code => {
            if (codes.indexOf(code.toString()) < 0) codes.push(code.toString());
            if (rackIndexesByCode[code.toString()] === undefined) rackIndexesByCode[code.toString()] = [];
            if (usersByCode[code.toString()] === undefined) usersByCode[code.toString()] = [];
            rackIndexesByCode[code.toString()].push(rackIx);
            if (usersByCode[code.toString()].findIndex(user => user.id === rack.user.id) < 0) usersByCode[code.toString()].push(rack.user);
        });
    });

    codes.sort();

    const nav = useNavigate();

    return (
        <div>
            <table className="table">
                <tbody>
                {codes.map((code, ix) => (
                    <tr key={code.toString()}>
                        <td>
                            <LocationIcon/>

                            {rackIndexesByCode[code].length === 0 ? code : (
                                <>
                                    <Button type={ButtonType.Link}
                                            onClick={() => setSelectedCode(selectedCode && code === selectedCode ? undefined : code)}
                                    >
                                        {code}
                                    </Button>
                                    {code === selectedCode ? <DownArrow/> : <RightArrow/>}
                                </>
                            )}
                            {code === selectedCode ? (
                                <div style={{margin: 20}}>
                                    <strong>Racks:</strong><br/>
                                    {rackIndexesByCode[code].map(rackIx => (
                                        <div key={code + '-' + racks[rackIx].id}>
                                            <ButtonSet>
                                                <Button type={ButtonType.Link}
                                                        onClick={() => {
                                                            nav('/racks/' + racks[rackIx].id);
                                                        }}>
                                                    {racks[rackIx].name && racks[rackIx].name.length > 0 ? racks[rackIx].name : 'Unnamed'}
                                                </Button>
                                                <Button type={ButtonType.Danger}
                                                        onClick={() => deleteRackAtIndex(rackIx)}>
                                                    Delete</Button>
                                            </ButtonSet>
                                        </div>
                                    ))}
                                </div>
                            ) : null}
                        </td>
                        <td>{rackIndexesByCode[code].length} racks</td>
                        <td>
                            <Tags>
                                {usersByCode[code].map(user => (
                                    <Tag key={user.id}>{user.name}</Tag>
                                ))}
                            </Tags>
                        </td>
                    </tr>
                ))}
                </tbody>
            </table>
        </div>
    );
}

const RightArrow: FunctionComponent = () => {
    return <div style={{
        margin: '0 5px',
        display: 'inline-block',
        width: 0,
        height: 0,
        borderTop: '5px solid transparent',
        borderLeft: '5px solid #000',
        borderBottom: '5px solid transparent'
    }}></div>
}

const DownArrow: FunctionComponent = () => {
    return <div style={{
        margin: '0 5px',
        display: 'inline-block',
        width: 0,
        height: 0,
        borderTop: '5px solid #000',
        borderLeft: '5px solid transparent',
        borderRight: '5px solid transparent'
    }}></div>
}

export default UserCodesTable;
