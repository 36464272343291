import BaseApi from './base-api';
import {Rack, SaveRack} from '../../types/rack';
import DistributionCode from '../../models/distribution-code';
import {RackSection, SaveRackSection} from '../../types/rack-section';
import {RacksResponse} from '../../types/server/racks-response';
import {AxiosRequestConfig, AxiosResponse} from 'axios';
import {RackResponse} from '../../types/server/rack-response';
import {RackPocketResponse, RackWithSectionsResponse} from '../../types/server/rack-with-sections-response';
import {RackCodeResponse} from '../../types/server/rack-code-response';
import {RackPocket} from '../../types/rack-pocket';
import {SuccessErrorsResponse} from '../../types/success-errors-response';
import {RackLayout} from '../../types/rack-layout';

class RackApi extends BaseApi {
    getRacks(showAllUsers: boolean = false): Promise<Array<Rack>> {
        const config: AxiosRequestConfig = {
            params: {
                users: showAllUsers ? 'all' : 'me'
            }
        };

        return this.api.get<Array<Rack>, AxiosResponse<RacksResponse>>('/racks', config).then(response => {
            return response.data.racks.map(rackResponse => {
                return this.serverRackToRack(rackResponse);
            });
        });
    }

    getRack(rackId: number): Promise<Rack> {
        return this.api.get<Rack, AxiosResponse<RackWithSectionsResponse>>('/racks/' + rackId)
            .then(response => {
                return this.serverRackToRack(response.data.rack, response.data.sections);
            });
    }

    getSections(rackId: number): Promise<Array<RackSection>> {
        return this.api.get<{sections: Array<RackSection>}>('/racks/' + rackId + '/sections')
            .then(response => response.data.sections);
    }

    getCodes(rackId: number): Promise<Array<RackCodeResponse>> {
        return this.api.get<{codes: Array<RackCodeResponse>}>('/racks/' + rackId + '/codes')
            .then(response => response.data.codes);
    }

    addCode(rackId: number, code: DistributionCode): Promise<RackCodeResponse> {
        return this.api.post<null, {code: string}, AxiosResponse<RackCodeResponse>>('/racks/' + rackId + '/codes', {code: code.toString()})
            .then(response => response.data);
    }

    deleteRack(rackId: number): Promise<SuccessErrorsResponse> {
        return this.api.delete<SuccessErrorsResponse>('/racks/' + rackId)
            .then(response => response.data);
    }

    deleteCode(rackId: number, codeId: number): Promise<SuccessErrorsResponse> {
        return this.api.delete<SuccessErrorsResponse>('/racks/' + rackId + '/codes/' + codeId)
            .then(response => response.data);
    }

    reorderCodes(rackId: number, codeIds: Array<number>): Promise<SuccessErrorsResponse> {
        return this.api.put<null, Array<number>, AxiosResponse<SuccessErrorsResponse>>('/racks/' + rackId + '/codes/reorder', codeIds)
            .then(response => response.data);
    }

    createSection(rackId: number, rackSection: SaveRackSection): Promise<RackSection> {
        return this.api.post<null, SaveRackSection, AxiosResponse<RackSection>>('/racks/' + rackId + '/sections', rackSection)
            .then(response => response.data);
    }

    saveSection(rackId: number, rackSection: SaveRackSection): Promise<RackSection> {
        if (rackSection.id === undefined) throw new Error('Attempting to save section without id');

        return this.api.put<null, SaveRackSection, AxiosResponse<RackSection>>('/racks/' + rackId + '/sections/' + rackSection.id, rackSection)
            .then(response => response.data);
    }

    reorderSections(rackId: number, rackSectionIds: Array<number>): Promise<SuccessErrorsResponse> {
        return this.api.put<null, Array<number>, AxiosResponse<SuccessErrorsResponse>>('/racks/' + rackId + '/sections/reorder', rackSectionIds)
            .then(response => response.data);
    }

    deleteSection(rackId: number, sectionId: number): Promise<SuccessErrorsResponse> {
        return this.api.delete<SuccessErrorsResponse>('/racks/' + rackId + '/sections/' + sectionId)
            .then(response => response.data);
    }

    createRack(name: string, note: string): Promise<Rack> {
        return this.api.post<null, SaveRack, AxiosResponse<RackResponse>>('/racks', {
            enable: true,
            name,
            note
        })
            .then(response => {
                return this.serverRackToRack(response.data)
            });
    }

    saveRack(saveRack: SaveRack): Promise<SuccessErrorsResponse> {
        return this.api.put<null, SaveRack, AxiosResponse<SuccessErrorsResponse>>('/racks/' + saveRack.id, saveRack)
            .then(response => {
                return response.data;
            });
    }

    setPocket(rackId: number, sectionId: number, rowIx: number, colIx: number, pocket?: RackPocket): Promise<RackPocket | undefined> {
        return this.api.put<null, RackPocket, AxiosResponse<{pocket?: RackPocketResponse}>>(
            '/racks/' + rackId + '/sections/' + sectionId + '/pockets/' + rowIx + '/' + colIx,
            pocket
        )
            .then(response => {
                if (response.data.pocket === undefined || response.data.pocket === null) return undefined;

                return this.pocketResponseToPocket(response.data.pocket)
            });
    }

    getLayouts(): Promise<Array<RackLayout>> {
        return this.api.get<{layouts: Array<RackLayout>}>('/racks/layouts').then(response => response.data.layouts);
    }

    private serverRackToRack(rackResponse: RackResponse, sectionsResponse?: Array<RackSection<RackPocketResponse>>): Rack {
        return {
            id: rackResponse.id,
            enable: rackResponse.enable,
            codes: rackResponse.codes.map(code => this.serverCodeToDistributionCode(code)),
            name: rackResponse.name,
            note: rackResponse.note,
            user: rackResponse.user,
            primaryCode: this.serverCodeToDistributionCode(rackResponse),
            sections: sectionsResponse === undefined ? [] : sectionsResponse.map(sectionResponse => {
                return {
                    ...sectionResponse,
                    pockets: sectionResponse.pockets.map(pocket => this.pocketResponseToPocket(pocket))
                };
            })
        } as Rack;
    }

    private pocketResponseToPocket(rackPocketResponse: RackPocketResponse): RackPocket
    {
        return {
            ...rackPocketResponse,
            start: rackPocketResponse.start === undefined || rackPocketResponse.start === null ? undefined : new Date(rackPocketResponse.start),
            end: rackPocketResponse.end === undefined || rackPocketResponse.end === null ? undefined : new Date(rackPocketResponse.end)
        } as RackPocket;
    }

    private serverCodeToDistributionCode(codeSource: RackResponse | RackCodeResponse): DistributionCode {
        return new DistributionCode(codeSource.cat_id, codeSource.prog_id, codeSource.type_id, codeSource.plan_id);
    }
}

export default RackApi;
