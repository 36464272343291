import {FunctionComponent, PropsWithChildren} from 'react';
import './panel.css';

type PanelProps = PropsWithChildren;

const Panel: FunctionComponent<PanelProps> = ({children}) => {
    return (
        <div className="Panel">
            {children}
        </div>
    );
}

export default Panel;
