import {FunctionComponent, PropsWithChildren} from 'react';
import './loading-indicator.css';

type LoadingIndicatorProps = PropsWithChildren;

const LoadingIndicator: FunctionComponent<LoadingIndicatorProps> = (props) => {
    return <div className="LoadingIndicator">
        <div className="LoadingIndicator-symbol">
            <div></div>
            <div></div>
            <div></div>
            <div></div>
        </div>
    </div>;
}

export default LoadingIndicator;
