import React, {FunctionComponent, PropsWithChildren} from 'react';
import './modal-content.css';

const ModalContent: FunctionComponent<PropsWithChildren> = ({children}) => {
    return (
        <div className="Modal-Content">
            {children}
        </div>
    )
};

export default ModalContent;
