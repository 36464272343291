import {ReactNode} from 'react';

class StackChangeEvent {
    public stack: Array<Modal> = [];
}

type ChangeHandler = (event: StackChangeEvent) => void;
type CloseHandler = () => void;

export class ModalOptions {
    private _onCloseHandler?: CloseHandler
    public canClickOutside: boolean = true; // When TRUE clicking outside will pop the current modal
    get onCloseHandler(): CloseHandler | undefined {
        return this._onCloseHandler;
    }

    onClose(handler: CloseHandler | undefined) {
        this._onCloseHandler = handler;
    }
}

type Modal = {
    options: ModalOptions
    view: ReactNode
}

export class ModalManager {
    private static _instance: ModalManager;
    private stack: Array<Modal> = [];
    private onChangeHandlers: Array<ChangeHandler> = [];

    public pushModal(view: ReactNode, options?: ModalOptions): Modal {
        if (!options) options = new ModalOptions();
        const modal: Modal = {
            view,
            options
        };
        this.stack.push(modal);

        this.triggerChange();

        return modal;
    }

    public popModal(): boolean {
        const wasPopped = this._popModal();
        if (wasPopped) this.triggerChange();

        return wasPopped;
    }

    /**
     * Pop all
     */
    public popAll() {
        while (this._popModal()) {
        }
        this.triggerChange();
    }

    private _popModal(): boolean {
        if (this.stack.length === 0) return false;

        const modal = this.stack.pop();
        if (modal?.options.onCloseHandler) {
            modal.options.onCloseHandler();
        }

        return true;
    }

    public onChange(handler: ChangeHandler) {
        this.onChangeHandlers.push(handler);
    }

    public removeOnChange(handler: ChangeHandler) {
        this.onChangeHandlers = this.onChangeHandlers.filter(testHandler => testHandler !== handler);
    }

    private triggerChange() {
        const changeEvent: StackChangeEvent = {
            stack: this.stack
        };
        this.onChangeHandlers.forEach(handler => {
            handler(changeEvent);
        });
    }

    static instance() {
        if (this._instance) {
            this._instance = new ModalManager();
        }
        return this._instance;
    }
}

export default ModalManager;
export type {Modal};
export {StackChangeEvent};
