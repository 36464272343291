import React, {FunctionComponent, PropsWithChildren, ReactNode} from 'react';
import './modal.css';
import classNames from 'classnames';

type ModalProps = PropsWithChildren & {
    title?: string
    className?: string
    headerButtons?: ReactNode
};

const Modal: FunctionComponent<ModalProps> = ({
                                                  className,
                                                  headerButtons,
                                                  title,
                                                  children
                                              }) => {
    return (
        <div className={classNames('Modal', className)} onClick={ev => {
            ev.stopPropagation();
        }}>
            {headerButtons || title ? (
                <div className="Modal-header">
                    {headerButtons ? <div className="Modal-header-buttons">{headerButtons}</div> : null}
                    {title === undefined ? null : (
                        <div className="Modal-title">{title}</div>
                    )}
                </div>
            ) : null}
            {children}
        </div>
    );
};

export default Modal;
