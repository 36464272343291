import {FunctionComponent, useState} from 'react';
import {SaveRack} from '../../types/rack';
import InputGroup from '../form/input-group';
import Input from '../form/input';
import update from 'immutability-helper';
import Button, {ButtonType} from '../common/button';
import {Status} from '../common/status-indicator';
import ErrorList from '../common/error-list';
import api from '../../services/api';
import {AxiosError} from 'axios';
import LoadingIndicator from '../common/loading-indicator';
import FormRow from '../form/form-row';
import ButtonSet from '../common/button-set';

type EditRackProps = {
    doneLabel: string
    rack: SaveRack
    onDone: (rack: SaveRack) => void
    onCancel?: () => void
};

const EditRackMeta: FunctionComponent<EditRackProps> = ({
                                                            doneLabel,
                                                            rack,
                                                            onDone,
                                                            onCancel
                                                        }) => {
    const [state, setState] = useState<{ status: Status, errors: Array<string> }>({
        status: Status.Initializing,
        errors: []
    });
    const [sessionRack, setSessionRack] = useState<SaveRack>(rack);

    return (
        <div>
            <ErrorList errors={state.errors}/>
            <FormRow>
                <InputGroup>
                    <label>Name:</label>
                    <Input value={sessionRack.name}
                           onChange={val => {
                               setSessionRack(update(sessionRack, {
                                   name: {$set: val}
                               }))
                           }}/>
                    <label>Note:</label>
                    <Input value={sessionRack.note}
                           type="textarea"
                           onChange={val => {
                               setSessionRack(update(sessionRack, {
                                   note: {$set: val}
                               }))
                           }}/>
                </InputGroup>
            </FormRow>
            <FormRow>
                <ButtonSet>
                    <Button disabled={sessionRack.name.length === 0 || state.status === Status.Busy}
                            onClick={() => {
                                setState({
                                    status: Status.Busy,
                                    errors: []
                                });
                                let request: Promise<any>;

                                if (sessionRack.id === undefined) {
                                    request = api().racks.createRack(sessionRack.name, sessionRack.note).then(rack => {
                                        const updatedSessionRack = update(sessionRack, {
                                            id: {$set: rack.id}
                                        });
                                        setSessionRack(updatedSessionRack);
                                        setState({...state, status: Status.Done})
                                        onDone(updatedSessionRack);
                                    });
                                } else {
                                    request = api().racks.saveRack(sessionRack)
                                        .then(response => {
                                            if (response.success) {
                                                setState({
                                                    status: Status.Done,
                                                    errors: []
                                                });
                                                onDone(sessionRack);
                                            } else {
                                                setState({
                                                    status: Status.Failed,
                                                    errors: response.errors ?? ['Response is missing errors']
                                                })
                                            }
                                        });
                                }
                                request.catch(e => {
                                    if (e instanceof AxiosError && e.response?.data.errors) {
                                        setState({
                                            status: Status.Failed,
                                            errors: e.response.data.errors
                                        })
                                    } else {
                                        setState({
                                            status: Status.Failed,
                                            errors: ['An unknown error occurred']
                                        })
                                    }
                                });
                            }}
                    >{doneLabel}</Button>
                    {onCancel &&
                        <Button type={ButtonType.Danger}
                                onClick={() => onCancel()}
                        >Cancel</Button>
                    }
                </ButtonSet>
                {state.status === Status.Busy && <><LoadingIndicator/> Saving...</>}
            </FormRow>
        </div>
    );
}

export default EditRackMeta;
