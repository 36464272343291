import {Fragment, FunctionComponent, useCallback, useEffect, useState} from 'react';
import {RackLayout} from '../../types/rack-layout';
import api from '../../services/api';
import LoadingIndicator from '../common/loading-indicator';
import {Status} from '../common/status-indicator';
import ErrorList from '../common/error-list';
import {RackSection, SaveRackSection} from '../../types/rack-section';
import Button, {ButtonType} from '../common/button';
import ButtonSet from '../common/button-set';
import {AxiosError} from 'axios';
import update from 'immutability-helper';
import InputGroup from '../form/input-group';
import Input from '../form/input';
import {ArrowDownIcon, ArrowUpIcon, CheckCircleIcon, EllipsisIcon, PencilIcon, TrashIcon, XCircleIcon} from '@primer/octicons-react';
import classNames from 'classnames';
import './edit-rack-sections.css';

type ManageSectionsProps = {
    rackId: number
    onChanged: () => void
};

const EditRackSections: FunctionComponent<ManageSectionsProps> = ({rackId}) => {
    const [state, setState] = useState<{ status: Status, errors: Array<string> }>({
        status: Status.Initializing,
        errors: []
    });
    const [sections, setSections] = useState<Array<RackSection>>([]);
    const [layouts, setLayouts] = useState<Array<RackLayout> | undefined>();
    const [editingSection, setEditingSection] = useState<{
        ix?: number,
        section?: SaveRackSection
    }>();

    const loadSections = useCallback(() => {
        setState(prevState => ({
            ...prevState,
            status: Status.Busy
        }));

        api().racks.getSections(rackId)
            .then(sections => {
                setSections(sections);
                setState(prevState => ({...prevState, status: Status.Done}));
            })
            .then(() => {
                api().racks.getLayouts().then(results => setLayouts(results));
            });
    }, [rackId]);

    function moveSection(ix: number, direction: number) {
        let reorderedSections: Array<RackSection> = [];
        const section = sections[ix];
        const newIx = direction > 0 ? ix + 1 : ix - 1;
        reorderedSections = update(sections, {
            $splice: [[ix, 1], [newIx, 0, section]]
        });

        const ids = reorderedSections.map(section => section.id);
        setState({
            status: Status.Busy,
            errors: []
        });
        setEditingSection({});
        setSections(reorderedSections);
        api().racks.reorderSections(rackId, ids)
            .then(() => {
                setState(prevState => ({
                    ...prevState,
                    status: Status.Done
                }))
            })
            .catch(e => {
                setState({
                    status: Status.Failed,
                    errors: ['Unexpected error: ' + e.message]
                })
            });
    }

    useEffect(() => {
        loadSections();
    }, [rackId, loadSections]);


    return (
        <div>
            <ErrorList errors={state.errors}/>
            {/*<Button onClick={() => setState(prevState => ({...prevState, status: Status.Done}))}>Click</Button>*/}
            {layouts === undefined || sections === undefined ? (
                <><LoadingIndicator/> Loading</>
            ) : (
                <>
                    {state.status === Status.Busy ? <div><LoadingIndicator/> Saving</div> : null}
                    {sections.length === 0 ? <p><strong>Add your first section: </strong></p> : null}
                    <select disabled={state.status === Status.Busy} value={''} onChange={ev => {
                        if (ev.target.value === '') return;

                        // Capture current sections in case we need to restore it
                        // const restoreSections = sections;

                        setState(prevState => ({
                            ...prevState,
                            status: Status.Busy,
                            errors: []
                        }));

                        let newSection: SaveRackSection;
                        if (ev.target.value === 'custom') {
                            newSection = {
                                cols: 8,
                                rows: 1,
                                is_custom: true,
                                name: 'Custom'
                            }
                        } else {
                            const selectedLayouts = layouts?.filter(layout => layout.id.toString() === ev.target.value);
                            if (selectedLayouts.length === 0) return; // Should not happen
                            newSection = {
                                cols: selectedLayouts[0].cols,
                                rows: selectedLayouts[0].rows,
                                name: selectedLayouts[0].model,
                                layout_id: selectedLayouts[0].id,
                                is_custom: false
                            }
                        }

                        api().racks.createSection(rackId!, newSection).then(section => {
                            setSections(update(sections, {
                                $push: [section]
                            }));
                            setState(prevState => ({
                                ...prevState,
                                status: Status.Done,
                            }));
                        }).catch(e => {
                            let errors: Array<string> = [];
                            if (e instanceof AxiosError && e.response?.data.errors) {
                                errors = e.response.data.errors;
                            } else {
                                errors = ['Unknown error: ' + e.message]
                            }
                            setState({
                                status: Status.Failed,
                                errors
                            });
                        });
                    }}>
                        <option value="">-- Add Section --</option>
                        <option value="">--------------------------</option>
                        <option value="custom">Custom Layout</option>
                        <option value="">--------------------------</option>
                        {layouts.map(layout => (
                            <option key={layout.id}
                                    value={layout.id}
                            >
                                {layout.model} ({layout.cols}x{layout.rows})
                            </option>
                        ))}
                    </select>

                    {sections.length === 0 ? null : (
                        <>
                            <hr/>
                            <table
                                className={classNames('table EditRackSections-table', {editing: editingSection && editingSection.ix !== undefined})}>
                                <thead>
                                <tr>
                                    <th>Name</th>
                                    <th>&nbsp;</th>
                                    <th>Rows</th>
                                    <th>Columns</th>
                                    <th>&nbsp;</th>
                                    <th>&nbsp;</th>
                                </tr>
                                </thead>
                                <tbody>
                                {sections.map((section, ix) => (
                                    <Fragment key={section.id}>
                                        <tr className={classNames({
                                            deleting: editingSection?.ix === ix && editingSection.section === undefined,
                                            active: editingSection?.ix === ix
                                        })}>
                                            <td>
                                                {editingSection?.ix === ix && editingSection.section === undefined ?
                                                    <LoadingIndicator/> : null}
                                                <EllipsisIcon/>&nbsp;
                                                {
                                                    section.is_custom ? (
                                                        <>
                                                            <Button type={ButtonType.Link} onClick={() => {
                                                                setEditingSection({
                                                                    ix: editingSection?.ix === ix ? undefined : ix,
                                                                    section: editingSection?.ix === ix ? undefined : createEditableSection(section)
                                                                });
                                                            }}>{section.name}</Button>
                                                            <Button type={ButtonType.Plain} onClick={() => {
                                                                setEditingSection({
                                                                    ix: editingSection?.ix === ix ? undefined : ix,
                                                                    section: editingSection?.ix === ix ? undefined : createEditableSection(section)
                                                                });
                                                            }}>
                                                                <PencilIcon/>
                                                            </Button>
                                                        </>
                                                    ) : (
                                                        section.name
                                                    )
                                                }
                                                {/*{section.is_custom && <> (custom)</>}*/}
                                            </td>
                                            <td width={0}>
                                                <ButtonSet>
                                                    {sections.length > 1 && ix > 0 && <Button onClick={() => {
                                                        moveSection(ix, -1);
                                                    }}><ArrowUpIcon/></Button>}

                                                    {sections.length > 1 && ix < (sections.length - 1) &&
                                                    <Button onClick={() => {
                                                        moveSection(ix, 1);
                                                    }}><ArrowDownIcon/></Button>}
                                                </ButtonSet>
                                            </td>

                                            <td>{section.rows}</td>
                                            <td>{section.cols}</td>
                                            <td>
                                                <ButtonSet>
                                                    <Button type={ButtonType.Danger}
                                                            onClick={() => {
                                                                if (!rackId) return;

                                                                setState({
                                                                    status: Status.Busy,
                                                                    errors: []
                                                                });
                                                                setEditingSection({
                                                                    ix,
                                                                    section: undefined
                                                                })

                                                                setTimeout(() => {
                                                                    if (window.confirm('Are you sure?')) {
                                                                        api().racks.deleteSection(rackId, sections[ix].id)
                                                                            .then(response => {
                                                                                if (response.success) {
                                                                                    setEditingSection({});
                                                                                    setSections(update(sections, {
                                                                                        $splice: [[ix, 1]]
                                                                                    }));
                                                                                    setState(prevState => ({
                                                                                        ...prevState,
                                                                                        status: Status.Done
                                                                                    }));
                                                                                } else {
                                                                                    setState({
                                                                                        status: Status.Failed,
                                                                                        errors: response.errors!
                                                                                    })
                                                                                }
                                                                            })
                                                                            .catch(e => {
                                                                                let errors: Array<string> = [];
                                                                                if (e instanceof AxiosError && e.response?.data.errors) {
                                                                                    errors = e.response.data.errors;
                                                                                } else {
                                                                                    errors = ['Unknown error: ' + e.message];
                                                                                }
                                                                                setEditingSection({});
                                                                                setState({
                                                                                    status: Status.Failed,
                                                                                    errors
                                                                                })
                                                                            });
                                                                    } else {
                                                                        setState(prevState => ({
                                                                            ...prevState,
                                                                            status: Status.Done,
                                                                        }));
                                                                        setEditingSection(undefined);
                                                                        return;
                                                                    }
                                                                }, 100);
                                                            }}
                                                    >
                                                        <TrashIcon/>
                                                    </Button>
                                                </ButtonSet>
                                            </td>
                                        </tr>
                                        {editingSection?.ix === ix && editingSection.section &&
                                        <tr className={classNames({active: editingSection?.ix === ix})}>
                                            <td colSpan={5} style={{paddingLeft: 50}}>
                                                <InputGroup>
                                                    <label>Name</label>
                                                    <Input value={editingSection.section?.name ?? ''}
                                                           onChange={val => {
                                                               if (!editingSection.section) return;
                                                               setEditingSection(update(editingSection,
                                                                   {
                                                                       section: {name: {$set: val}}
                                                                   }
                                                               ));
                                                           }}/>
                                                    <label>Rows</label>
                                                    <Input
                                                        value={editingSection.section && editingSection.section.rows !== 0 ? editingSection.section?.rows.toString() : ''}
                                                        onChange={val => {
                                                            if (!editingSection.section) return;
                                                            let newVal: number = parseInt(val);
                                                            if (val.length === 0) newVal = 0;
                                                            else if (val.length > 2) return;
                                                            else if (val.length > 0) {
                                                                if (isNaN(newVal) || newVal > 20) return;
                                                            }
                                                            setEditingSection(update(editingSection,
                                                                {
                                                                    section: {rows: {$set: newVal}}
                                                                }
                                                            ));
                                                        }}/>
                                                    <label>Columns</label>
                                                    <Input
                                                        value={editingSection.section && editingSection.section.cols !== 0 ? editingSection.section?.cols.toString() : ''}
                                                        onChange={val => {
                                                            if (!editingSection.section) return;
                                                            let newVal: number = parseInt(val);
                                                            if (val.length === 0) newVal = 0;
                                                            else if (val.length > 2) return;
                                                            else if (val.length > 0) {
                                                                if (isNaN(newVal) || newVal > 20) return;
                                                            }
                                                            setEditingSection(update(editingSection,
                                                                {
                                                                    section: {cols: {$set: newVal}}
                                                                }
                                                            ));
                                                        }}/>
                                                    {/*<div></div>*/}
                                                    {/*<Button onClick={() => setState({*/}
                                                    {/*    status: Status.Done,*/}
                                                    {/*    errors: []*/}
                                                    {/*})}>Reset</Button>*/}
                                                    <div></div>
                                                    {state.status === Status.Busy ?
                                                        <div><LoadingIndicator/> Saving...</div> : (
                                                            <ButtonSet>
                                                                <Button type={ButtonType.Primary}
                                                                        disabled={editingSection.section?.name.length === 0 || editingSection.section?.rows === 0 || editingSection.section?.cols === 0}
                                                                        onClick={() => {
                                                                            if (editingSection.ix === undefined || !editingSection.section || !rackId) return;
                                                                            setState({
                                                                                status: Status.Busy,
                                                                                errors: []
                                                                            });
                                                                            setSections(update(sections, {
                                                                                [editingSection.ix]: {
                                                                                    name: {$set: editingSection.section.name},
                                                                                    cols: {$set: editingSection.section.cols},
                                                                                    rows: {$set: editingSection.section.rows}
                                                                                }
                                                                            }));
                                                                            api().racks.saveSection(rackId, editingSection.section).then(() => {
                                                                                setEditingSection({
                                                                                    ix: undefined,
                                                                                    section: undefined
                                                                                });
                                                                                loadSections();
                                                                            });
                                                                        }}><CheckCircleIcon/> Save</Button>
                                                                <Button type={ButtonType.Danger}
                                                                        onClick={() => {
                                                                            setEditingSection({
                                                                                ix: undefined,
                                                                                section: undefined
                                                                            });
                                                                        }}><XCircleIcon/> Cancel</Button>
                                                            </ButtonSet>
                                                        )}
                                                </InputGroup>
                                            </td>
                                        </tr>
                                        }
                                    </Fragment>
                                ))}
                                </tbody>
                            </table>
                        </>
                    )}
                </>
            )}
        </div>
    );
}

function createEditableSection(section: RackSection): SaveRackSection {
    return {
        cols: section.cols,
        rows: section.rows,
        name: section.name,
        id: section.id,
        is_custom: section.is_custom
    };
}

export default EditRackSections;
